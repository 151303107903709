import React, { useEffect, useState } from "react";
import { Container } from "./styles";
import MainTable from "../../../../components/MainTable";
import api from "../../../../services/api";
import { Title } from "../../../../styles/global";

const SolicitanteExterno: React.FC = () => {
  const [solicitantesExternos, setSolicitantesExternos] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const delayedSearch = setTimeout(() => {
      api
        .get(`external_requesters?busca_rapida=${searchValue}`)
        .then((response) => {
          setSolicitantesExternos(response.data);
        })
        .catch((error) => console.log(error));
    }, 500);

    return () => clearTimeout(delayedSearch);
  }, [searchValue]);

  const handlePagination = (goBack = false) => {
    const page = goBack ? -1 : 1;

    api
      .get(`administrations?page=${solicitantesExternos.pagination.current_page + page}`)
      .then((response) => {
        setSolicitantesExternos(response.data);
      })
      .catch((error) => console.log(error));
  };

  const columnData = [
    { headerName: "Código", field: "id" },
    { headerName: "Nome", field: "nome" },
    { headerName: "Situação", field: "ativo" },
  ];

  const organizeData = (data: any) => {
    if (typeof data === "object") {
      data = data.map((d: any) => ({
        id: d.id,
        nome: d.nome,
        ativo: d.ativo,
      }));
    }

    return data;
  };

  return (
    <>
      <Title color="#333">
        <span>Gestão de Solicitante Externo</span>
      </Title>
      <Container color="#FFF">
        <MainTable
          onSearchChange={setSearchValue}
          data={organizeData(solicitantesExternos.data)}
          pagination={solicitantesExternos.pagination}
          handlePagination={handlePagination}
          hideFilter={true}
          isMaxWidthCell
          registerLink="/protocolo/cadastros/solicitante_externo/cadastrar"
          columns={columnData}
          dropdown={{
            view: { linkTo: "/protocolo/cadastros/solicitante_externo/visualizar" },
            editar: { linkTo: "/protocolo/cadastros/solicitante_externo/editar" },
            desativar: { linkTo: "external_requesters" },
          }}
        />
      </Container>
    </>
  );
};

export default SolicitanteExterno;
