import React, { createContext, useCallback, useContext, useState } from "react";
import api from '../services/api';
import { useToast } from "./toast";

interface SignInCredentials {
    cpf: string;
    password: string;
}

interface AuthContextData {
    login: User;
    signIn(credentials: SignInCredentials): Promise<void>;
    signOut(): void;
}

interface AuthNodeData {
    children: React.ReactNode;
    cpf?: string;
    password?: string;
}

interface User {
    nome: string;
    // email: string;
    // organization: object;
}

interface AuthState {
    token: string;
    login: User;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC<AuthNodeData> = ({ children }) => {
    const {addToast} = useToast();

    const [data, setData] = useState<AuthState>(() => {
        const token = localStorage.getItem('@Siga:token');
        const login = localStorage.getItem('@Siga:login');
        console.log(token, login);

        if (token && login) {
            api.defaults.headers.authorization = `Bearer ${token}`;
            return { token, login: JSON.parse(login) };
        }

        return {} as AuthState;
    });

    const signIn = useCallback(async ({cpf, password}) => {


        const response = await api.post('auth', {
            cpf,
            password
        });
        
        const {token} = response.data;
        const {login} = response.data;

        if (!token) {
            console.log(!token);
            addToast({
                type: 'error',
                title: 'Login não efetuado',
                description: 'Verifique seus dados.',
            });
            return;
        }

        addToast({
            type: 'success',
            title: 'Login efetuado',
            description: 'Seu login foi feito com sucesso!',
        });

        localStorage.setItem('@Siga:token', token);
        localStorage.setItem('@Siga:login', JSON.stringify(login));
        
        api.defaults.headers.authorization = `Bearer ${token}`;

        setData({token, login});
    }, [addToast]);

    const signOut = useCallback(() => {
        localStorage.removeItem('@Siga:token');
        localStorage.removeItem('@Siga:login');

        addToast({
            type: 'info',
            title: 'Sessão expirada!',
            description: "Refaça o login para continuar.",
        });

        setData({} as AuthState);
    }, []);

    return (
        <AuthContext.Provider value={{login: data.login, signIn, signOut}}>
            {children}
        </AuthContext.Provider>
    );
};

export function useAuth(): AuthContextData {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }

    return context;
}