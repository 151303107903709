import { FormHandles } from "@unform/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Panel from "../../../components/Panel";
import useUsuario from "../../../actions/cruds/useUsuario";

import {
  ContainerButton,
  Form,
  Input,
  SelectInput,
  TextTitle,
  Title,
} from "../../../styles/global";

import { Container, FormControl } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faX } from "@fortawesome/free-solid-svg-icons";
import Grid from "@mui/material/Unstable_Grid2";
import Radio from "../../../components/InputRadio";
import SecondaryTable from "../../../components/SecondaryTable";
import { GridColDef } from "@mui/x-data-grid";
import useRepresentanteInstituicao from "../../../actions/cruds/useRepresentanteInstituicao";
import { formatCPF, pontuarCpf } from "../../../utils/formatDados";
import useModulo from "../../../actions/cruds/useModulo";
import usePerfilAcesso from "../../../actions/cruds/usePerfilAcesso";
import useLogin from "../../../actions/cruds/useLogin";


export function EditUsers() {
  const { loginById, getLoginById, updateLogin } = useLogin();
  const { usuarios, getUsers } = useUsuario();
  const { representantesInstituicao, getRepresentantesInstituicao } = useRepresentanteInstituicao();
  const { modulos, getModulos } = useModulo();
  const { perfisAcesso, getPerfisAcesso } = usePerfilAcesso();
  const { loginId } = useParams();

  const [tipoUsuario, setTipoUsuario] = useState<String>();
  const [valueCPF, setValueCPF] = useState<Object>({});
  const [valueNome, setValueNome] = useState<Object>({});
  const [dados, setDados] = useState([]);
  const [empregadoId, setEmpregadoId] = useState<Number>();
  const [representanteId, setRepresentanteId] = useState<Number>();
  const [defaultValueDados, setDefaultValueDados] = useState([]);

  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    getLoginById(loginId);

    getUsers([{ label: "ativo", value: true }]);
    getRepresentantesInstituicao([{ label: "ativo", value: true }]);
    getModulos([{ label: "ativo", value: true }, { label: "per", value: "200" }]);
    getPerfisAcesso([{ label: "ativo", value: true }, { label: "per", value: "200" }]);
  }, [])


  useEffect(() => {
    console.log(loginById)
    setTipoUsuario(loginById?.tipo_usuario);

    const tipoUsuarioRadio = formRef.current?.getFieldRef("tipo_usuario");
    if (tipoUsuario == 'empregado') {
      tipoUsuarioRadio[0].checked = true;
      setValueCPF({ value: loginById?.cpf, label: pontuarCpf(loginById?.cpf) });
      setValueNome({ value: loginById?.nome, label: loginById?.nome });
    } else if (tipoUsuario == 'representante') {
      tipoUsuarioRadio[1].checked = true;
      setValueCPF({ value: loginById?.cpf, label: pontuarCpf(loginById?.cpf) });
      setValueNome({ value: loginById?.nome, label: loginById?.nome });
    } else if (tipoUsuario == 'externo'){
      console.log('externo')
      tipoUsuarioRadio[2].checked = true;
    };

    setDados(loginById?.ver_organizacoes);
    if (dados?.length > 0) {
      dados.forEach((obj) => {
        let initialValueDados = optionsDadosOrganizacoes.find((org) => org.value == obj)

        if (!defaultValueDados.some((item) => item.value === initialValueDados.value)) {
          setDefaultValueDados((prevValue) => [
            ...prevValue,
            initialValueDados
          ]);
        };

      });
    };

  }, [loginById])

  const handleSubmit = useCallback(
    async (data) => {
      try {

        const perfilAcessoPorModulo = [];

        Object.keys(data).forEach(obj => {
          const moduloPerfil = parseInt(obj, 10);
          if (!isNaN(moduloPerfil)) {
            const moduloEncontrado = modulos.data.find((modulo) => modulo.id == moduloPerfil);
            if (moduloEncontrado) {
              const idPerfilCadastrado = loginById.login_profiles.filter((profile) => profile.profile.tbl_module.id == moduloEncontrado.id);
              perfilAcessoPorModulo.push({ id: idPerfilCadastrado[0].id, profile_id: data[obj] });
            }
          }
        });

        if (tipoUsuario == "empregado") {
          data["user_id"] = empregadoId;
          if (loginById?.representative) {
            data["representative_id"] = '';
          };
        } else if (tipoUsuario == 'representante') {
          data["representative_id"] = representanteId;
          if (loginById?.user) {
            data["user_id"] = '';
          };
        } else if (tipoUsuario == 'externo') {
          if (loginById?.representative) {
            data["representative_id"] = '';
          } else if (loginById?.user) {
            data["user_id"] = '';
          };
        };

        data["login_profiles_attributes"] = perfilAcessoPorModulo;
        data["ver_organizacoes"] = dados;
        data["password"] = "Agevap@Siga";
        console.log("dataSubmit", data, loginId);
        updateLogin(loginId, data);

      } catch (err) {
      }
    }, [loginId, dados, modulos, empregadoId, representanteId, tipoUsuario]
  );

  const organizeDataCPF = (data: any) => {
    if (typeof data === 'object') {
      data = data.map(d => ({
        value: d.cpf,
        label: pontuarCpf(d.cpf),
        id: d.id,
        nome: d.nome,
        email: d.email,
      }));
    }
    return data;
  };

  const organizeDataNome = (data: any) => {
    if (typeof data === 'object') {
      data = data.map(d => ({
        value: d.nome,
        label: d.nome,
        id: d.id,
        cpf: d.cpf,
        email: d.email,
      }));
    }
    return data;
  };

  const organizeDataPermissao = (data: any, id: string) => {
    if (typeof data === 'object') {
      data = data.filter((perm) => perm.tbl_module?.id == id);

      data = data.map(d => ({
        value: d.id,
        // label: `${d.id} - ${d.nome} - ${d.tbl_module?.nome}`, Verifica id e nome do múdulo durante o desenvolvimento
        label: d.nome,
        module: d.tbl_module?.id,
      }));
    };

    return data;
  };

  const organizeDataPermissaoDefault = (data: any, id: string) => {
    if (typeof data === 'object') {
      data = data.filter((perm) => perm.profile.tbl_module?.id == id);


      data = data.map(d => ({
        value: d.profile.id,
        // label: `${d.profile.id} - ${d.profile.nome} - ${d.profile.tbl_module?.nome}`, Verifica id e nome do múdulo durante o desenvolvimento
        label: d.profile.nome,
        id_perfil: d.id,
      }));
    };

    return data;
  };

  const optionsTipoUsuario = [
    { value: "empregado", label: " Empregado" },
    { value: "representante", label: " Representante" },
    { value: "externo", label: " Acesso externo (novo usuário)" },
  ];

  const optionsDadosOrganizacoes = [
    { value: 1, label: "AGEVAP" },
    { value: 2, label: "AGEDOCE" },
  ]

  const dataTitlePermissao: GridColDef[] = [
    { field: "id", headerName: "Item", flex: 1 },
    { field: "nome", headerName: "Módulo", flex: 2 },
    {
      field: "permissao",
      headerName: "Permissão",
      cellClassName: "muiDataGridCellOverflowVisible",
      sortable: false,
      flex: 2,
      renderCell: (params) => {
        return (
          <FormControl fullWidth={true}>
            {
              perfisAcesso.data && (
                <SelectInput
                  name={`${params.row.id}`}
                  defaultValue={organizeDataPermissaoDefault(loginById.login_profiles, params.row.id)}
                  options={organizeDataPermissao(perfisAcesso.data, params.row.id)}
                  required
                >
                </SelectInput>
              )}
          </FormControl>
        )
      }
    },
  ];

  const handlerChangeTipoUsuarioRadio = () => {
    const tipoUsuarioRadioField = formRef.current?.getFieldValue("tipo_usuario");
    setTipoUsuario(tipoUsuarioRadioField);
    formRef.current?.setFieldValue("email", "")
    setValueCPF({});
    setValueNome({})
  };

  const resetValues = () => {
    setValueNome({});
    setValueCPF({});
    formRef.current?.setFieldValue("email", "");
  };

  const handlerChangeCpf = (cpfSelected: object) => {
    if (!cpfSelected) {
      resetValues();
      return;
    };
    console.log(cpfSelected);

    setEmpregadoId(cpfSelected?.id);
    setRepresentanteId(cpfSelected?.id);

    setValueCPF(cpfSelected);
    setValueNome({ value: cpfSelected?.nome, label: cpfSelected?.nome })
    formRef.current?.setFieldValue("email", cpfSelected?.email);
  };

  const handlerChangeNome = (nomeSelected: object) => {
    if (!nomeSelected) {
      resetValues();
      return;
    };
    console.log(nomeSelected)

    setEmpregadoId(nomeSelected?.id);
    setRepresentanteId(nomeSelected?.id);

    setValueNome(nomeSelected);
    setValueCPF({ value: nomeSelected?.cpf, label: pontuarCpf(nomeSelected?.cpf) })
    formRef.current?.setFieldValue("email", nomeSelected?.email ? nomeSelected.email : "");
  };

  const handlerDados = (optionDados: Array<Object>) => {
    let dadosHelper = [];
    optionDados.map((o) => dadosHelper.push(o.value));
    setDados(dadosHelper);

    if (optionDados.length) {
      const dadosValues = dadosHelper.map((obj) =>
        optionsDadosOrganizacoes.find((org) => org.value === obj)
      );
      setDefaultValueDados(dadosValues);

    } else {
      setDefaultValueDados([]);
    }
  };

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Title color="#333">
          <span>Edição de usuário</span>
        </Title>
        <Panel titulo="Cadastro do usuário">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Selecione o tipo do usuário: </TextTitle>
              <Radio
                name="tipo_usuario"
                options={optionsTipoUsuario}
                onChange={handlerChangeTipoUsuarioRadio}
              />
            </Grid>
          </Grid>
        </Panel>
        {loginById && (
          <>
            <Panel titulo="Cadastro do usuário">
              <Grid container spacing={6}>

                {tipoUsuario == 'externo' ? (
                  <>
                    <Grid xs={12} lg={6}>
                      <TextTitle>CPF: </TextTitle>
                      <Input type="text" name="cpf" mask="cpf" defaultValue={loginById ? formatCPF(loginById.cpf) : ''} required/>
                    </Grid>
                    <Grid xs={12} lg={6}>
                      <TextTitle>Nome: </TextTitle>
                      <Input type="text" name="nome" defaultValue={loginById?.nome} required/>
                    </Grid>
                  </>
                ) : tipoUsuario == 'empregado' ? (
                  <>
                    <Grid xs={12} lg={6}>
                      <TextTitle>CPF: </TextTitle>
                      <SelectInput
                        name="cpf"
                        options={organizeDataCPF(usuarios.data)}
                        value={valueCPF}
                        onChange={handlerChangeCpf}
                        placeholder="Selecionar"
                        onInputChange={(e) => {
                          getUsers([{ label: "cpf", value: e }, { label: 'ativo', value: true }]);
                        }}
                        required
                      />
                    </Grid>
                    <Grid xs={12} lg={6}>
                      <TextTitle>Nome: </TextTitle>
                      <SelectInput
                        name="nome"
                        options={organizeDataNome(usuarios.data)}
                        value={valueNome}
                        onChange={handlerChangeNome}
                        placeholder="Selecionar"
                        onInputChange={(e) => {
                          getUsers([{ label: "nome", value: e }, { label: 'ativo', value: true }]);
                        }}
                        required
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid xs={12} lg={6}>
                      <TextTitle>CPF REPRESENTANTE: </TextTitle>
                      <SelectInput
                        name="cpf"
                        options={organizeDataCPF(representantesInstituicao.data)}
                        value={valueCPF}
                        onChange={handlerChangeCpf}
                        placeholder="Selecionar"
                        onInputChange={(e) => {
                          getRepresentantesInstituicao([{ label: "cpf", value: e }, { label: 'ativo', value: true }]);
                        }}
                        required
                      />
                    </Grid>
                    <Grid xs={12} lg={6}>
                      <TextTitle>Nome: </TextTitle>
                      <SelectInput
                        name="nome"
                        options={organizeDataNome(representantesInstituicao.data)}
                        value={valueNome}
                        onChange={handlerChangeNome}
                        placeholder="Selecionar"
                        onInputChange={(e) => {
                          getRepresentantesInstituicao([{ label: "nome", value: e }, { label: 'ativo', value: true }]);
                        }}
                        required
                      />
                    </Grid>
                  </>
                )}
                <Grid xs={12} lg={6}>
                  <TextTitle>Email: </TextTitle>
                  <Input type="email" name="email" defaultValue={loginById?.email} required />
                </Grid>
                <Grid xs={12} lg={6}>
                  <TextTitle>Selecione quais dados o usuário irá visualizar: </TextTitle>
                  <SelectInput
                    isMulti
                    name="dados_usuario"
                    options={optionsDadosOrganizacoes}
                    value={defaultValueDados}
                    onChange={handlerDados}
                    placeholder="Selecionar"
                  />
                </Grid>
              </Grid>
            </Panel>

            <Panel titulo="Permissões">
              <Grid container spacing={6}>
                <Grid xs={12}>
                  <SecondaryTable
                    columsData={dataTitlePermissao}
                    rowsData={modulos.data}
                  />
                </Grid>
              </Grid>
            </Panel>
          </>
        )}

        <ContainerButton>
          <Link to="/usuarios">
            <button>
              <FontAwesomeIcon icon={faX} size="1x" /> Cancelar
            </button>
          </Link>
          <button>
            <FontAwesomeIcon icon={faSave} /> Salvar
          </button>
        </ContainerButton>
      </Form>
    </Container>
  );
};