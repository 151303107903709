import React from "react";
import {
  RouteProps as ReactDOMRouteProps,
  Route as ReactDOMRoute,
  Redirect,
} from "react-router-dom";
import Header from "../components/Header";
import { CSidebar } from "../components/Sidebar";

import { useAuth } from "../hooks/auth";
import { Container, ContainerButton } from "../styles/global";

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  initialComponents?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  initialComponents = false,
  component: Component,
  ...rest
}) => {
  const { login } = useAuth();

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return isPrivate === !!login ? (
          initialComponents ? (
            <div
              style={{
                display: "flex",
                height: "100%",
                backgroundColor: "#ffffff",
              }}
            >
              <div style={{ height: "100%" }} className="lateralMenuContainer">
                {/* <div style={{position: 'fixed'}}> */}
                <CSidebar />
                {/* </div> */}
              </div>
              <div style={{ display: "block", width: "100%" }}>
                <Header />
                <Container>
                  <Component />
                </Container>
              </div>
            </div>
          ) : (
            <Component />
          )
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? "/" : "/dashboard",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default Route;
