import { useEffect } from "react";
import useDescricao from "../../../../actions/cruds/useDescricao";
import MainTable from "../../../../components/MainTable";
import { Title } from "../../../../styles/global";
import { Container } from "./styles";


export function Descricoes() {

  const { descricao, getDescricoes } = useDescricao();


  useEffect(() => {
    getDescricoes();
  }, [])

  const organizeData = (data: any) => {

    if (typeof data === 'object') {
      data = data.map(d => ({
        id: d.id,
        codigo: d.codigo,
        nome: d.nome,
        ativo: d.ativo
      }));
    }
    return data;
  }

  const columnData = [
    { headerName: "Código", field: "codigo" },
    { headerName: "Descrição", field: "nome" },
    { headerName: "Status", field: "ativo" }
  ];

  const handlePagination = (goBack = false) => {
    const page = goBack ? -1 : 1;

    getDescricoes([{ label: "page", value: descricao.pagination.current_page + page }]);
  };

  return (
    <>
      <Title color='#333'>
        <span>
          Gestão de descrição
        </span>
      </Title>
      <Container color='#FFF'>
        <MainTable
          data={organizeData(descricao.data)}
          pagination={descricao.pagination}
          handlePagination={handlePagination}
          hideFilter={true}
          registerLink="/orcamentario/cadastros/descricoes/cadastrar"
          columns={columnData}
          dropdown={{
            editar: { linkTo: "/orcamentario/cadastros/descricoes/editar" },
            desativar: { linkTo: "descriptions", desactiveText: "Desativar" },
          }}
        />
      </Container>
    </>
  )
}