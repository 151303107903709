import { useEffect } from "react";
import useDescricao from "../../../actions/cruds/useDescricao";
import Header from "../../../components/Header";
import MainTable from "../../../components/MainTable";
import { CSidebar } from "../../../components/Sidebar";
import Table2 from "../../../components/Table2/Table2";
import { Container, Title } from "../../../styles/global";
import { DialogInDev } from "../../../components/ModalEmDev";


export function Orcamento(){

  const { descricao, getDescricoes} = useDescricao();


  useEffect(() => {
    getDescricoes();
  },[])

  const organizeData = (data: any ) => {

    if(typeof data === 'object'){
      data = data.map(d => ({
        id: d.id,
        codigo: d.codigo,
        nome: d.nome,
        ativo: d.ativo
      }));
    }
    return data;
  }
  
  const columnData = [
    { headerName: "Ano", field: "ano" },
    { headerName: "Receita", field: "receita" },
    { headerName: "Organização", field: "organizacao" },
    { headerName: "Valor", field: "valor" },
    { headerName: "Status", field: "ativo" }
  ];

  const handlePagination = (goBack = false) => {
    const page = goBack ? -1 : 1;

    getDescricoes([{label: "page", value: descricao.pagination.current_page + page}]);
  };

  return( 
    <>
      <Title color='#333'>
        <span>
          Gestão de Orçamento
        </span>
      </Title>
      <MainTable
        // data={organizeData(descricao.data)}
        data={[{id: 1, ano: 2024, receita: 'Custeio', organizacao: 'MATRIZ', valor: "R$ 25.000,00", ativo: "ativo"}]}
        pagination={descricao.pagination}
        handlePagination={handlePagination}
        hideFilter={true}
        registerLink="/cadastrarorcamento"
        columns={columnData}
        dropdown={{
            editar: { linkTo: "editardescricao" },
            desativar: { linkTo: "descriptions", desactiveText: "Desativar" },
        }}
      />
      <DialogInDev open={true} modulo={"Gestão de Orçamento"}></DialogInDev>
    </>
  )
}