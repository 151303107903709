import { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../services/api";
import stringfyParams from "../../utils/stringfyParams";

const useModulo = () => {
  const history = useHistory();
  const [modulos, setModulos] = useState([]);
  const [error, setError] = useState(null);

  async function getModulos(params) {
    try {
      const response = await api.get(`tbl_modules${stringfyParams(params)}`);
      setModulos(response.data);
      return response.data;
    } catch (e) {
      setError(e);
    }
  }

  return {modulos, getModulos};
};

export default useModulo;