import { useEffect, useState } from "react";
import { Title } from "../../../styles/global";
import MainTable from "../../../components/MainTable";
import { Container } from "./styles";
import { formatarData } from "../../../utils/formatDados";
import useDotacao from "../../../actions/cruds/Orcamentario/useDotacao";
import ModalCodigoNup from "../../../components/CodigoNupModal";

const OrcamentarioDotacao: React.FC = () => {
  const { dotacoes, getDotacoes } = useDotacao();

  const [numeroDotacao, setNumeroDotacao] = useState<number>(0);
  const [showModalCodigoNup, setShowModalCodigoNup] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [perPage, setPerPage] = useState<number>()

  const optionsSituacao = [
    { label: "Aguardando validação", value: "aguardando_validacao" },
    { label: "Concluído", value: "concluido" },
    { label: "Cancelado", value: "cancelado" },
  ];

  useEffect(() => {
    const delayedSearch = setTimeout(() => {
      getDotacoes([{ label: "busca_rapida", value: searchValue }, { label: "per", value: perPage }]);
    }, 500);

    return () => clearTimeout(delayedSearch);
  }, [searchValue]);

  const handleItemsPerPage = (per_page: number) => {
    setPerPage(per_page);
    getDotacoes([{ label: "busca_rapida", value: searchValue }, { label: "per", value: per_page }]);
  };

  const handlePagination = (goBack = false) => {
    const page = goBack ? -1 : 1;

    getDotacoes([{ label: "busca_rapida", value: searchValue }, { label: "per", value: perPage }, { label: "page", value: dotacoes.pagination.current_page + page }]);
  };

  const organizeData = (data: any[]) => {
    const withoutData = <i>Sem dados</i>;

    if (typeof data === "object") {
      data = data.map((d) => ({
        id: d.id,
        numero: d.numero ?? withoutData,
        codigo_nup: d.codigo_nup ?? withoutData,
        data: formatarData(d.data_solicitacao, false),
        assunto: d.assunto ?? withoutData,
        parecer: d.parecer ?? withoutData,
        situacao: d.situacao ? optionsSituacao.find(o => o.value == d?.situacao)?.label : withoutData,
        ativo: d.ativo,
      }));
    }
    return data;
  };

  const columnData = [
    { headerName: "Número da Dotação", field: "numero" },
    { headerName: "Data da solicitação", field: "data" },
    { headerName: "Código NUP - SIGAD", field: "codigo_nup" },
    { headerName: "Assunto", field: "assunto" },
    { headerName: "Parecer", field: "parecer" },
    { headerName: "Situação", field: "situacao" },
  ];

  return (
    <>
      <Title color="#333">
        <span>Gestão de Dotação</span>
      </Title>
      <Container color="#FFF">
        <ModalCodigoNup
          itemId={numeroDotacao}
          route={"allocations"}
          isOpen={showModalCodigoNup}
          handleClose={() => setShowModalCodigoNup(false)}
        />

        <MainTable
          columns={columnData}
          data={organizeData(dotacoes.data)}
          pagination={dotacoes.pagination}
          isMaxWidthCell
          handleItemsPerPage={handleItemsPerPage}
          handlePagination={handlePagination}
          onSearchChange={setSearchValue}
          registerLink="/orcamentario/dotacao/cadastrar"
          dropdown={{
            view: { linkTo: "/orcamentario/dotacao/visualizar" },
            validarDotacao: { linkTo: "/orcamentario/dotacao/validar" },
            editar: { linkTo: "/orcamentario/dotacao/editar" },
            codigoNup: {
              openModal: () => setShowModalCodigoNup(true),
              updateId: setNumeroDotacao,
            },
            cancelar: { linkTo: "allocations", activateSituacao: "aguardando_validacao" }
          }}
        />
      </Container>
    </>
  );
};

export default OrcamentarioDotacao;
