import {
  faAddressCard,
  faBars,
  faChartColumn,
  faCircle,
  faCoins,
  faDolly,
  faFileSignature,
  faFolderPlus,
  faHelmetSafety,
  faIdBadge,
  faNewspaper,
  faUserGear,
  faUsers,
  faBook,
  faFileEdit,
  faFileContract,
  faFile,
  faFileLines,
  faLandmark,
  faBriefcase,
  faFileInvoiceDollar,
  faSackDollar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Link } from "react-router-dom";
import sigaLogo from "../../assets/sigaLogo.png";
import {
  CollapseBtn,
  Container,
  StyledMenu,
  StyledMenuItem,
  StyledSidebar,
  StyledSubMenu,
} from "./styles";

export function CSidebar() {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Container>
      <StyledSidebar collapsed={collapsed}>
        <div>
          <img
            src={sigaLogo}
            style={{ width: "120px", margin: "10px 0px" }}
            alt=""
          />
        </div>
        <StyledMenu>
          <Link to="/dashboard" style={{ textDecoration: "none" }}>
            <StyledMenuItem
              icon={<FontAwesomeIcon icon={faChartColumn} size="xl" />}
            >
              Dashboard
            </StyledMenuItem>
          </Link>

          {/* Gestão de usuários */}
          <Link to="/usuarios" style={{ textDecoration: "none" }}>
            <StyledMenuItem
              icon={<FontAwesomeIcon icon={faUserGear} size="xl" />}
            >
              Usuários
            </StyledMenuItem>
          </Link>

          {/* Gestão de fornecedores */}
          <StyledSubMenu
            label="Gestão de fornecedores"
            icon={<FontAwesomeIcon icon={faHelmetSafety} size="xl" />}
          >
            <Link to="" style={{ textDecoration: "none" }}>
              <StyledMenuItem
                icon={<FontAwesomeIcon icon={faChartColumn} size="xl" />}
              >
                Dashboard
              </StyledMenuItem>
            </Link>
            <Link to="/fornecedores" style={{ textDecoration: "none" }}>
              <StyledMenuItem
                icon={<FontAwesomeIcon icon={faDolly} size="xl" />}
              >
                Fornecedores
              </StyledMenuItem>
            </Link>
            <StyledSubMenu
              label="Cadastros"
              icon={<FontAwesomeIcon icon={faFolderPlus} size="xl" />}
            >
              <Link to="/fornecedores/cnae" style={{ textDecoration: "none" }}>
                <StyledMenuItem
                  icon={<FontAwesomeIcon icon={faCircle} size="1x" />}
                >
                  Atividades CNAE
                </StyledMenuItem>
              </Link>
            </StyledSubMenu>
            <Link
              to="/relatorios/fornecedores"
              style={{ textDecoration: "none" }}
            >
              <StyledMenuItem
                icon={<FontAwesomeIcon icon={faNewspaper} size="xl" />}
              >
                Relatório
              </StyledMenuItem>
            </Link>
          </StyledSubMenu>

          {/* Gestão de membros */}
          <StyledSubMenu
            label="Gestão de membros "
            icon={<FontAwesomeIcon icon={faUsers} size="xl" />}
          >
            <Link to="/membros/dashboard" style={{ textDecoration: "none" }}>
              <StyledMenuItem
                icon={<FontAwesomeIcon icon={faChartColumn} size="xl" />}
              >
                Dashboard
              </StyledMenuItem>
            </Link>
            <Link to="/membros/instituicoes" style={{ textDecoration: "none" }}>
              <StyledMenuItem
                icon={<FontAwesomeIcon icon={faUserGear} size="xl" />}
              >
                Instituição
              </StyledMenuItem>
            </Link>
            <Link
              to="/membros/representantes"
              style={{ textDecoration: "none" }}
            >
              <StyledMenuItem
                icon={<FontAwesomeIcon icon={faAddressCard} size="xl" />}
              >
                Representantes
              </StyledMenuItem>
            </Link>
            <StyledSubMenu
              label="Relatórios"
              icon={<FontAwesomeIcon icon={faNewspaper} size="xl" />}
            >
              <Link
                to="/relatorios/instituicoes"
                style={{ textDecoration: "none" }}
              >
                <StyledMenuItem
                  icon={<FontAwesomeIcon icon={faCircle} size="1x" />}
                >
                  Membros
                </StyledMenuItem>
              </Link>
              <Link
                to="/relatorios/representantes"
                style={{ textDecoration: "none" }}
              >
                <StyledMenuItem
                  icon={<FontAwesomeIcon icon={faCircle} size="1x" />}
                >
                  Representantes
                </StyledMenuItem>
              </Link>
            </StyledSubMenu>
          </StyledSubMenu>

          {/* Gestão de instrumentos */}
          <StyledSubMenu
            label="Gestão de Instrumentos"
            icon={<FontAwesomeIcon icon={faFileSignature} size="xl" />}
          >
            <Link
              to="/instrumentos/dashboard"
              style={{ textDecoration: "none" }}
            >
              <StyledMenuItem
                icon={<FontAwesomeIcon icon={faChartColumn} size="xl" />}
              >
                Dashboard
              </StyledMenuItem>
            </Link>
            <Link to="/instrumentos" style={{ textDecoration: "none" }}>
              <StyledMenuItem
                icon={<FontAwesomeIcon icon={faFileSignature} size="xl" />}
              >
                Instrumentos
              </StyledMenuItem>
            </Link>
            <Link
              to="/relatorios/instrumentos"
              style={{ textDecoration: "none" }}
            >
              <StyledMenuItem
                icon={<FontAwesomeIcon icon={faNewspaper} size="xl" />}
              >
                Relatório
              </StyledMenuItem>
            </Link>
          </StyledSubMenu>

          {/* RecursosHumanos */}
          <StyledSubMenu
            label="Recursos humanos"
            icon={<FontAwesomeIcon icon={faIdBadge} size="xl" />}
          >
            <Link to="/rh/dashboard" style={{ textDecoration: "none" }}>
              <StyledMenuItem
                icon={<FontAwesomeIcon icon={faChartColumn} size="xl" />}
              >
                Dashboard
              </StyledMenuItem>
            </Link>
            <Link to="/rh/empregados" style={{ textDecoration: "none" }}>
              <StyledMenuItem
                icon={<FontAwesomeIcon icon={faIdBadge} size="xl" />}
              >
                Empregados
              </StyledMenuItem>
            </Link>
            <StyledSubMenu
              label="Cadastros"
              icon={<FontAwesomeIcon icon={faFolderPlus} size="xl" />}
            >
              <Link
                to="/rh/cadastros/gerencia"
                style={{ textDecoration: "none" }}
              >
                <StyledMenuItem
                  icon={<FontAwesomeIcon icon={faCircle} size="1x" />}
                >
                  Gerência
                </StyledMenuItem>
              </Link>
              <Link to="/rh/cadastros/cargo" style={{ textDecoration: "none" }}>
                <StyledMenuItem
                  icon={<FontAwesomeIcon icon={faCircle} size="1x" />}
                >
                  Cargo
                </StyledMenuItem>
              </Link>
              <Link
                to="/rh/cadastros/organizacao"
                style={{ textDecoration: "none" }}
              >
                <StyledMenuItem
                  icon={<FontAwesomeIcon icon={faCircle} size="1x" />}
                >
                  Organização
                </StyledMenuItem>
              </Link>
              <Link
                to="/rh/cadastros/unidade"
                style={{ textDecoration: "none" }}
              >
                <StyledMenuItem
                  icon={<FontAwesomeIcon icon={faCircle} size="1x" />}
                >
                  Unidade
                </StyledMenuItem>
              </Link>
            </StyledSubMenu>
            <Link
              to="/relatorios/empregados"
              style={{ textDecoration: "none" }}
            >
              <StyledMenuItem
                icon={<FontAwesomeIcon icon={faNewspaper} size="xl" />}
              >
                Relatório
              </StyledMenuItem>
            </Link>
          </StyledSubMenu>

          {/* Financeiro */}
          <StyledSubMenu
            label="Financeiro"
            icon={<FontAwesomeIcon icon={faLandmark} size="xl" />}
          >
            <Link to="" style={{ textDecoration: "none" }}>
              <StyledMenuItem
                icon={<FontAwesomeIcon icon={faChartColumn} size="xl" />}
              >
                Dashboard
              </StyledMenuItem>
            </Link>
            <Link to="/financeiro/formularioateste" style={{ textDecoration: "none" }}>
              <StyledMenuItem
                icon={<FontAwesomeIcon icon={faFileSignature} size="xl" />}
              >
                Formulário de ateste
              </StyledMenuItem>
            </Link>
            <Link to="/financeiro/autorizacaodespesa" style={{ textDecoration: "none" }}>
              <StyledMenuItem
                icon={<FontAwesomeIcon icon={faFileInvoiceDollar} size="xl" />}
              >
                Autorização de despesa
              </StyledMenuItem>
            </Link>
            <StyledSubMenu
              label="Cadastros"
              icon={<FontAwesomeIcon icon={faFolderPlus} size="xl" />}
            >
              <Link to="/financeiro/cadastros/contabancaria" style={{ textDecoration: "none" }}>
                <StyledMenuItem
                  icon={<FontAwesomeIcon icon={faCircle} size="1x" />}
                >
                  Conta bancária
                </StyledMenuItem>
              </Link>
            </StyledSubMenu>
            <Link
              to="/financeiro/relatorios"
              style={{ textDecoration: "none" }}
            >
              <StyledMenuItem
                icon={<FontAwesomeIcon icon={faNewspaper} size="xl" />}
              >
                Relatório
              </StyledMenuItem>
            </Link>
          </StyledSubMenu>

          {/* Orçamentário */}
          <StyledSubMenu
            label="Orçamentário"
            icon={<FontAwesomeIcon icon={faCoins} size="xl" />}
          >
            <Link to="" style={{ textDecoration: "none" }}>
              <StyledMenuItem
                icon={<FontAwesomeIcon icon={faChartColumn} size="xl" />}
              >
                Dashboard
              </StyledMenuItem>
            </Link>
            <Link to="/orcamentario/orcamento" style={{ textDecoration: "none" }}>
              <StyledMenuItem
                icon={<FontAwesomeIcon icon={faFileLines} size="xl" />}
              >
                Orçamento
              </StyledMenuItem>
            </Link>
            <Link to="/orcamentario/pap" style={{ textDecoration: "none" }}>
              <StyledMenuItem
                icon={<FontAwesomeIcon icon={faFileContract} size="xl" />}
              >
                PAP
              </StyledMenuItem>
            </Link>
            <Link to="/orcamentario/poa" style={{ textDecoration: "none" }}>
              <StyledMenuItem
                icon={<FontAwesomeIcon icon={faFileSignature} size="xl" />}
              >
                POA
              </StyledMenuItem>
            </Link>
            <Link to="/orcamentario/dotacao" style={{ textDecoration: "none" }}>
              <StyledMenuItem
                icon={<FontAwesomeIcon icon={faFileLines} size="xl" />}
              >
                Dotação
              </StyledMenuItem>
            </Link>
            <Link to="/orcamentario/receita" style={{ textDecoration: "none" }}>
              <StyledMenuItem
                icon={<FontAwesomeIcon icon={faSackDollar} size="xl" />}
              >
                Receita
              </StyledMenuItem>
            </Link>
            <StyledSubMenu
              label="Cadastros"
              icon={<FontAwesomeIcon icon={faFolderPlus} size="xl" />}
            >
              <Link to="/orcamentario/cadastros/despesas" style={{ textDecoration: "none" }}>
                <StyledMenuItem
                  icon={<FontAwesomeIcon icon={faCircle} size="1x" />}
                >
                  Despesas
                </StyledMenuItem>
              </Link>
              <Link to="/orcamentario/cadastros/orgaosgestores" style={{ textDecoration: "none" }}>
                <StyledMenuItem
                  icon={<FontAwesomeIcon icon={faCircle} size="1x" />}
                >
                  Orgão Gestor
                </StyledMenuItem>
              </Link>
              <Link to="/orcamentario/cadastros/contratosgestao" style={{ textDecoration: "none" }}>
                <StyledMenuItem
                  icon={<FontAwesomeIcon icon={faCircle} size="1x" />}
                >
                  Contrato de Gestão
                </StyledMenuItem>
              </Link>
              <Link to="/orcamentario/cadastros/comites" style={{ textDecoration: "none" }}>
                <StyledMenuItem
                  icon={<FontAwesomeIcon icon={faCircle} size="1x" />}
                >
                  Comitê
                </StyledMenuItem>
              </Link>
              <Link to="/orcamentario/cadastros/descricoes" style={{ textDecoration: "none" }}>
                <StyledMenuItem
                  icon={<FontAwesomeIcon icon={faCircle} size="1x" />}
                >
                  Descrição
                </StyledMenuItem>
              </Link>
            </StyledSubMenu>
            <Link
              to="/orcamentario/relatorios"
              style={{ textDecoration: "none" }}
            >
              <StyledMenuItem
                icon={<FontAwesomeIcon icon={faNewspaper} size="xl" />}
              >
                Relatório
              </StyledMenuItem>
            </Link>
          </StyledSubMenu>

          {/* Protocolo */}
          <StyledSubMenu
            label="Protocolo"
            icon={<FontAwesomeIcon icon={faFile} size="xl" />}
          >
            <Link to="" style={{ textDecoration: "none" }}>
              <StyledMenuItem
                icon={
                  <FontAwesomeIcon
                    icon={faChartColumn}
                    size="xl"
                  />
                }
              >
                Dashboard
              </StyledMenuItem>
            </Link>
            <Link to="/protocolo" style={{ textDecoration: "none" }}>
              <StyledMenuItem
                icon={
                  <FontAwesomeIcon
                    icon={faFile}
                    size="xl"
                  />
                }
              >
                Protocolo
              </StyledMenuItem>
            </Link>
            <StyledSubMenu
              label="Cadastros"
              icon={<FontAwesomeIcon icon={faFolderPlus} size="xl" />}
            >
              <Link
                to="/protocolo/cadastros/solicitante_externo"
                style={{ textDecoration: "none" }}
              >
                <StyledMenuItem
                  icon={<FontAwesomeIcon icon={faCircle} size="1x" />}
                >
                  Solicitante Externo
                </StyledMenuItem>
              </Link>
              <Link to="/protocolo/cadastros/origem_demanda" style={{ textDecoration: "none" }}>
                <StyledMenuItem
                  icon={<FontAwesomeIcon icon={faCircle} size="1x" />}
                >
                  Origem da Demanda
                </StyledMenuItem>
              </Link>
              <Link to="/protocolo/cadastros/instancia" style={{ textDecoration: "none" }}>
                <StyledMenuItem
                  icon={<FontAwesomeIcon icon={faCircle} size="1x" />}
                >
                  Instância do Protocolo
                </StyledMenuItem>
              </Link>
            </StyledSubMenu>
            <Link
              to="/protocolo/relatorios"
              style={{ textDecoration: "none" }}
            >
              <StyledMenuItem
                icon={<FontAwesomeIcon icon={faNewspaper} size="xl" />}
              >
                Relatório
              </StyledMenuItem>
            </Link>
          </StyledSubMenu>

          {/* Projetos */}
          <StyledSubMenu
            label="Projetos"
            icon={<FontAwesomeIcon icon={faFileLines} size="xl" />}
          >
            <Link to="" style={{ textDecoration: "none" }}>
              <StyledMenuItem
                icon={<FontAwesomeIcon icon={faChartColumn} size="xl" />}
              >
                Dashboard
              </StyledMenuItem>
            </Link>
            <Link to="/projetos" style={{ textDecoration: "none" }}>
              <StyledMenuItem
                icon={<FontAwesomeIcon icon={faFileLines} size="xl" />}
              >
                Projetos
              </StyledMenuItem>
            </Link>
            <Link
              to="/projetos/relatorios"
              style={{ textDecoration: "none" }}
            >
              <StyledMenuItem
                icon={<FontAwesomeIcon icon={faNewspaper} size="xl" />}
              >
                Relatório
              </StyledMenuItem>
            </Link>
          </StyledSubMenu>

          {/* Processos */}
          <StyledSubMenu
            label="Processos"
            icon={<FontAwesomeIcon icon={faFileContract} size="xl" />}
          >
            <Link to="" style={{ textDecoration: "none" }}>
              <StyledMenuItem
                icon={<FontAwesomeIcon icon={faChartColumn} size="xl" />}
              >
                Dashboard
              </StyledMenuItem>
            </Link>
            <Link to="/processos" style={{ textDecoration: "none" }}>
              <StyledMenuItem
                icon={<FontAwesomeIcon icon={faFileContract} size="xl" />}
              >
                Processos
              </StyledMenuItem>
            </Link>
            <Link
              to="/processos/relatorios"
              style={{ textDecoration: "none" }}
            >
              <StyledMenuItem
                icon={<FontAwesomeIcon icon={faNewspaper} size="xl" />}
              >
                Relatório
              </StyledMenuItem>
            </Link>
          </StyledSubMenu>

          {/* Jurídico */}
          <StyledSubMenu
            label="Jurídico"
            icon={<FontAwesomeIcon icon={faBriefcase} size="xl" />}
          >
            <Link to="" style={{ textDecoration: "none" }}>
              <StyledMenuItem
                icon={<FontAwesomeIcon icon={faChartColumn} size="xl" />}
              >
                Dashboard
              </StyledMenuItem>
            </Link>
            <Link to="/juridico" style={{ textDecoration: "none" }}>
              <StyledMenuItem
                icon={<FontAwesomeIcon icon={faBriefcase} size="xl" />}
              >
                Jurídico
              </StyledMenuItem>
            </Link>
            <Link
              to="/juridico/relatorios"
              style={{ textDecoration: "none" }}
            >
              <StyledMenuItem
                icon={<FontAwesomeIcon icon={faNewspaper} size="xl" />}
              >
                Relatório
              </StyledMenuItem>
            </Link>
          </StyledSubMenu>

          {/* Bens e patrimonios */}
          {/* <Link to="/bens-e-patrimonios" style={{ textDecoration: "none" }}>
            <StyledMenuItem
              icon={<FontAwesomeIcon icon={faIdBadge} size="1x" />}
            >
              {" "}
              Bens e Patrimônio
            </StyledMenuItem>
          </Link> */}
          {/* Almoxarifado e estoque */}
          {/* <Link to="/almoxarifado-e-estoque" style={{ textDecoration: "none" }}>
            <StyledMenuItem
              icon={<FontAwesomeIcon icon={faIdBadge} size="1x" />}
            >
              {" "}
              Almoxarifado e Estoque
            </StyledMenuItem>
          </Link> */}
          {/* Assinatura */}
          {/* <Link to="/assinatura" style={{ textDecoration: "none" }}>
            <StyledMenuItem
              icon={<FontAwesomeIcon icon={faIdBadge} size="1x" />}
            >
              {" "}
              Gestão de Assinatura
            </StyledMenuItem>
          </Link> */}

        </StyledMenu>
      </StyledSidebar>

      <CollapseBtn onClick={() => setCollapsed(!collapsed)}>
        <FontAwesomeIcon icon={faBars} size="xl" />
      </CollapseBtn>
    </Container>
  );
}
