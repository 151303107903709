import { GlobalStyle } from './styles/global';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowDown,
  faBars,
  faBell,
  faChartColumn,
  faCircleUser,
  faDownload,
  faEye,
  faFileArrowDown,
  faPen,
  faPenToSquare,
  faPlus,
  faRightFromBracket,
  faTrashCan,
  faUserGroup,
  faUsers
} from '@fortawesome/free-solid-svg-icons';


import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';


import AppProvider from './hooks';
// import { AuthProvider } from './hooks/auth';


library.add(faUsers, faChartColumn, faBell, faCircleUser, faRightFromBracket, faBars, faUserGroup, faPlus, faTrashCan, faFileArrowDown, faPen, faEye, faPenToSquare, faDownload);

export function App() {
  return (
    <>
      <BrowserRouter>
        <AppProvider>
          <Routes />
        </AppProvider>

        <GlobalStyle />
      </BrowserRouter>
      </>
  )
}

// const App: React.FC = () => (
//   <>
//     <BrowserRouter>
//       <AppProvider>
//         <Routes />
//       </AppProvider>

//       <GlobalStyle />
//     </BrowserRouter>
//   </>
// );

// export default App;
