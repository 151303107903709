import { useEffect } from "react";
import useDespesas from "../../../../actions/cruds/useDespesas";
import MainTable from "../../../../components/MainTable";

import {
  Title
} from "../../../../styles/global";
import { Container } from "./styles";


export function Despesas() {

  const { despesas, getDespesas } = useDespesas();

  useEffect(() => {
    getDespesas();
  }, []);

  const handlePagination = (goBack = false) => {
    const page = goBack ? -1 : 1;

    getDespesas([{ label: "page", value: despesas.pagination.current_page + page }]);
  };

  const organizeData = (data: any) => {
    if (typeof data === 'object') {
      data = data.map(d => ({
        id: d.id,
        codigo: d.codigo,
        nome: d.nome,
        ativo: d.ativo
      }));
    }
    return data;
  }

  const columnData = [
    { headerName: "Código", field: "codigo" },
    { headerName: "Despesa", field: "nome" },
    { headerName: "Status", field: "ativo" }
  ];

  return (
    <>
      <Title color='#333'>
        <span>
          Gestão de despesas
        </span>
      </Title>
      <Container color="#FFF">
        <MainTable
          data={organizeData(despesas.data)}
          pagination={despesas.pagination}
          handlePagination={handlePagination}
          hideFilter={true}
          registerLink="/orcamentario/cadastros/despesas/cadastrar"
          columns={columnData}
          dropdown={{
            editar: { linkTo: "/orcamentario/cadastros/despesas/editar" },
            desativar: { linkTo: "expense_types", desactiveText: "Desativar" },
          }}
        />
      </Container>
    </>
  )
}