import React from "react";
import { Switch } from "react-router-dom";
import Route from "./Route";

import { Fornecedores } from "../pages/Fornecedores";
import { AtividadeCNAE } from "../pages/Fornecedores/CNAE";
import { RegCNAE } from "../pages/Fornecedores/CNAE/CreateCNAE";
import { EditCNAE } from "../pages/Fornecedores/CNAE/EditCNAE";
import { CertidaoFornecedor } from "../pages/Fornecedores/Certidao";
import { RegFornecedor } from "../pages/Fornecedores/Create";
import { EditFornecedor } from "../pages/Fornecedores/Edit";
import { ViewFornecedor } from "../pages/Fornecedores/View";
import Instrumentos from "../pages/Instrumentos";
import InstrumentoAditivoCreate from "../pages/Instrumentos/Aditivo/Create";
import InstrumentoAditivoView from "../pages/Instrumentos/Aditivo/View";
import InstrumentoAditivoEdit from "../pages/Instrumentos/Aditivo/Edit";
import InstrumentoApostilamentoCreate from "../pages/Instrumentos/Apostilamento/Create";
import InstrumentoApostilamentoView from "../pages/Instrumentos/Apostilamento/View";
import InstrumentoApostilamentoEdit from "../pages/Instrumentos/Apostilamento/Edit";
import InstrumentoCreate from "../pages/Instrumentos/Create";
import InstrumentoEdit from "../pages/Instrumentos/Editar";
import InstrumentoView from "../pages/Instrumentos/View";
import Login from "../pages/Login/index";
// import { Acoes } from "../pages/Orcamentario/Cadastros/Acao";
// import { RegAcao } from "../pages/Orcamentario/Cadastros/Acao/Create";
// import { EditAcao } from "../pages/Orcamentario/Cadastros/Acao/Edit";
import { Comites } from "../pages/Orcamentario/Cadastros/Comite";
import { RegComite } from "../pages/Orcamentario/Cadastros/Comite/Create";
import { EditComite } from "../pages/Orcamentario/Cadastros/Comite/Edit";
import { ContratosGestao } from "../pages/Orcamentario/Cadastros/ContratoGestao";
import { RegContratoGestao } from "../pages/Orcamentario/Cadastros/ContratoGestao/Create";
import { EditContratoGestao } from "../pages/Orcamentario/Cadastros/ContratoGestao/Edit";
import { Descricoes } from "../pages/Orcamentario/Cadastros/Descricao";
import { RegDescricao } from "../pages/Orcamentario/Cadastros/Descricao/Create";
import { EditDescricao } from "../pages/Orcamentario/Cadastros/Descricao/Edit";
import { Despesas } from "../pages/Orcamentario/Cadastros/Despesas";
import { RegDespesa } from "../pages/Orcamentario/Cadastros/Despesas/Create";
import { EditDespesa } from "../pages/Orcamentario/Cadastros/Despesas/Edit";
// import { Finalidades } from "../pages/Orcamentario/Cadastros/Finalidade";
// import { RegFinalidade } from "../pages/Orcamentario/Cadastros/Finalidade/Create";
// import { EditFinalidade } from "../pages/Orcamentario/Cadastros/Finalidade/Edit";
import { OrgaosGestores } from "../pages/Orcamentario/Cadastros/OrgaoGestor";
import { RegOrgaoGestor } from "../pages/Orcamentario/Cadastros/OrgaoGestor/Create";
import { EditOrgaoGestor } from "../pages/Orcamentario/Cadastros/OrgaoGestor/Edit";
// import { Programas } from "../pages/Orcamentario/Cadastros/Programa";
// import { RegPrograma } from "../pages/Orcamentario/Cadastros/Programa/Create";
// import { EditPrograma } from "../pages/Orcamentario/Cadastros/Programa/Edit";
// import { Projetos } from "../pages/Projeto";
// import { RegProjeto } from "../pages/Projeto/Create";
// import { EditProjeto } from "../pages/Projeto/Edit";
// import { SubAcoes } from "../pages/Orcamentario/Cadastros/Subacao";
// import { RegSubAcao } from "../pages/Orcamentario/Cadastros/Subacao/Create";
// import { EditSubAcao } from "../pages/Orcamentario/Cadastros/Subacao/Edit";
import { Users } from "../pages/Users";
import { CreateUsers } from "../pages/Users/Create";
import { ViewUsers } from "../pages/Users/View";
import { EditUsers } from "../pages/Users/Edit";
import Dashboard from "../pages/dashboard";
import { Members } from "../pages/members";
import { RegMembers } from "../pages/members/Create";
import { EditMembers } from "../pages/members/Edit";
import { ViewMembers } from "../pages/members/View";
import { createInstitutionDocument } from "../pages/members/documents";
import { Representantes } from "../pages/representantes";
import { RegRepresentante } from "../pages/representantes/Create";
import { EditRepresentante } from "../pages/representantes/Edit";
import { ViewRepresentante } from "../pages/representantes/View";
import { Empregados } from "../pages/RecursosHumanos/Empregados";
import { RegEmpregados } from "../pages/RecursosHumanos/Empregados/Create";
import { RegEmpregado } from "../pages/RecursosHumanos/Empregados/Create/Empregado";
import { RegEstagiario } from "../pages/RecursosHumanos/Empregados/Create/Estagiario";
import { RegJovemAprendiz } from "../pages/RecursosHumanos/Empregados/Create/JovemAprendiz";
import { RegTerceirizado } from "../pages/RecursosHumanos/Empregados/Create/Terceirizado";
import DocumentosEmpregados from "../pages/RecursosHumanos/Empregados/Documentos";
import { ViewEmpregado } from "../pages/RecursosHumanos/Empregados/View";
import RelatorioInstituicao from "../pages/members/Relatorios";
import RelatorioRepresentantes from "../pages/representantes/Relatorios";
import RelatorioFornecedores from "../pages/Fornecedores/Relatorios";
import EditEmpregado from "../pages/RecursosHumanos/Empregados/Edit";
import InstrumentsDashboard from "../pages/Instrumentos/Dashboard";
import MembersDashboard from "../pages/members/Dashboard";
import RhDashboard from "../pages/RecursosHumanos/Dashboard";
import RelatorioInstrumentos from "../pages/Instrumentos/Relatorios";
import RelatorioEmpregados from "../pages/RecursosHumanos/Empregados/Relatorios";
import BensPatrimonios from "../pages/BensPatrimonios";
import Assinatura from "../pages/Assinatura";
import AlmoxarifadoEstoque from "../pages/AlmoxarifadoEstoque";
import GerenciaRh from "../pages/RecursosHumanos/Cadastros/Gerencia";
import GerenciaRhCreate from "../pages/RecursosHumanos/Cadastros/Gerencia/Create";
import GerenciaRhEdit from "../pages/RecursosHumanos/Cadastros/Gerencia/Edit";
import GerenciaRhView from "../pages/RecursosHumanos/Cadastros/Gerencia/View";
import CargoRh from "../pages/RecursosHumanos/Cadastros/Cargo";
import CargoRhCreate from "../pages/RecursosHumanos/Cadastros/Cargo/Create";
import CargoRhEdit from "../pages/RecursosHumanos/Cadastros/Cargo/Edit";
import CargoRhView from "../pages/RecursosHumanos/Cadastros/Cargo/View";
import OrganizacaoRh from "../pages/RecursosHumanos/Cadastros/Organizacao";
import OrganizacaoRhCreate from "../pages/RecursosHumanos/Cadastros/Organizacao/Create";
import OrganizacaoRhEdit from "../pages/RecursosHumanos/Cadastros/Organizacao/Edit";
import OrganizacaoRhView from "../pages/RecursosHumanos/Cadastros/Organizacao/View";
import UnidadeRh from "../pages/RecursosHumanos/Cadastros/Unidade";
import UnidadeRhCreate from "../pages/RecursosHumanos/Cadastros/Unidade/Create";
import UnidadeRhEdit from "../pages/RecursosHumanos/Cadastros/Unidade/Edit";
import UnidadeRhView from "../pages/RecursosHumanos/Cadastros/Unidade/View";
import Pap from "../pages/Orcamentario/PAP";
import PapOrcamento from "../pages/Orcamentario/PAP/orcamento";
import Poa from "../pages/Orcamentario/POA";
import PoaCreateIdentificacao from "../pages/Orcamentario/POA/Create/Identificacao";
import PapCreateIdentificacao from "../pages/Orcamentario/PAP/Create/Identificacao";
import PapCreateFinalidade from "../pages/Orcamentario/PAP/Create/Finalidade";
import PapCreatePrograma from "../pages/Orcamentario/PAP/Create/Programa";
import PapCreateAcao from "../pages/Orcamentario/PAP/Create/Acao";
import PapView from "../pages/Orcamentario/PAP/View";
import PapEditIdentificacao from "../pages/Orcamentario/PAP/Edit/Identificacao";
import PoaView from "../pages/Orcamentario/POA/View";
import PoaEditIdentificacao from "../pages/Orcamentario/POA/Edit/Identificacao";
import PoaEditSubacao from "../pages/Orcamentario/POA/Edit/subacao";
import { Projetos } from "../pages/Projeto";
import { RegProjeto } from "../pages/Projeto/Create";
import { EditProjeto } from "../pages/Projeto/Edit";
import Protocolo from "../pages/Protocolo";
import ProtocoloCreate from "../pages/Protocolo/Create";
import ProtocoloView from "../pages/Protocolo/View";
import ProtocoloEdit from "../pages/Protocolo/Edit";
import { LinhaProjeto } from "../pages/Projeto/Linha";
import { ViewProjeto } from "../pages/Projeto/View";
import { ProtocoloComprovante } from "../pages/Protocolo/Comprovante";
import { CreateProjectDocument } from "../pages/Projeto/Documento";
import RelatorioProtocolo from "../pages/Protocolo/Relatorios";
import SolicitanteExterno from "../pages/Protocolo/Cadastros/SolicitanteExterno";
import SolicitanteExternoCreate from "../pages/Protocolo/Cadastros/SolicitanteExterno/Create";
import SolicitanteExternoView from "../pages/Protocolo/Cadastros/SolicitanteExterno/View";
import SolicitanteExternoEdit from "../pages/Protocolo/Cadastros/SolicitanteExterno/Edit";
import OrigemDemanda from "../pages/Protocolo/Cadastros/OrigemDemanda";
import OrigemDemandaCreate from "../pages/Protocolo/Cadastros/OrigemDemanda/Create";
import OrigemDemandaView from "../pages/Protocolo/Cadastros/OrigemDemanda/View";
import OrigemDemandaEdit from "../pages/Protocolo/Cadastros/OrigemDemanda/Edit";
import PoaOrcamento from "../pages/Orcamentario/POA/Orcamento";
import InstanciaProtocolo from "../pages/Protocolo/Cadastros/InstanciaProtocolo";
import InstanciaProtocoloCreate from "../pages/Protocolo/Cadastros/InstanciaProtocolo/Create";
import InstanciaProtocoloView from "../pages/Protocolo/Cadastros/InstanciaProtocolo/View";
import InstanciaProtocoloEdit from "../pages/Protocolo/Cadastros/InstanciaProtocolo/Edit";
import Processos from "../pages/Processos";
import ProcessoCreate from "../pages/Processos/Create";
import ProcessoView from "../pages/Processos/View";
import ProcessoEdit from "../pages/Processos/Edit";
import ProcessoNormaInterna from "../pages/Processos/NormaInterna";
import ProcessoPlanejamento from "../pages/Processos/Planejamento";
import ProcessoAcaoPlanejamento from "../pages/Processos/AcaoPlanejamento";
import ProcessoAtualizarAcaoPlanejamento from "../pages/Processos/AcaoPlanejamento/AtualizarAcaoPlanejamento";
import ProcessoVincularProtocolo from "../pages/Processos/VincularProtocolo";
import TipoProcesso from "../pages/Processos/Cadastros/TipoProcesso";
import TipoProcessoCreate from "../pages/Processos/Cadastros/TipoProcesso/Create";
import TipoProcessoView from "../pages/Processos/Cadastros/TipoProcesso/View";
import TipoProcessoEdit from "../pages/Processos/Cadastros/TipoProcesso/Edit";
import Juridico from "../pages/Juridico";
import JuridicoCreate from "../pages/Juridico/Create";
import JuridicoView from "../pages/Juridico/View";
import JuridicoEdit from "../pages/Juridico/Edit";
import JuridicoFinalizarProcesso from "../pages/Juridico/FinalizarProcesso";
import JuridicoHistoricoProcesso from "../pages/Juridico/Historico";
import FinanceiroContaBancaria from "../pages/Financeiro/Cadastros/Conta";
import FinanceiroContaBancariaCreate from "../pages/Financeiro/Cadastros/Conta/Create";
import FinanceiroContaBancariaView from "../pages/Financeiro/Cadastros/Conta/View";
import FinanceiroContaBancariaEdit from "../pages/Financeiro/Cadastros/Conta/Edit";
import FinanceiroAteste from "../pages/Financeiro/Ateste";
import FinanceiroAtesteCreate from "../pages/Financeiro/Ateste/Create";
import FinanceiroAtesteView from "../pages/Financeiro/Ateste/View";
import FinanceiroAtesteEdit from "../pages/Financeiro/Ateste/Edit";
import ProcessoFichaPDF from "../pages/Processos/FichaPDF";
import FinanceiroAutorizacaoDespesaCreate from "../pages/Financeiro/AutorizacaoDespesa/Create";
import FinanceiroAutorizacaoDespesa from "../pages/Financeiro/AutorizacaoDespesa";
import FinanceiroRealizarPagamentoDespesa from "../pages/Financeiro/AutorizacaoDespesa/RealizarPagamento";
import FinanceiroInformarPagamentoDespesa from "../pages/Financeiro/AutorizacaoDespesa/InformarPagamento";
import FinanceiroAutorizacaoDespesaView from "../pages/Financeiro/AutorizacaoDespesa/View";
import OrcamentarioDotacao from "../pages/Orcamentario/Dotacao";
import OrcamentarioDotacaoCreate from "../pages/Orcamentario/Dotacao/Create";
import OrcamentarioDotacaoView from "../pages/Orcamentario/Dotacao/View";
import OrcamentarioDotacaoEdit from "../pages/Orcamentario/Dotacao/Edit";
import OrcamentarioDotacaoValidar from "../pages/Orcamentario/Dotacao/ValidarDotacao";
import { Orcamento } from "../pages/Orcamentario/Orcamento";
import { CadastrarOrcamento } from "../pages/Orcamentario/Orcamento/Create";
import ProjetoRelatorio from "../pages/Projeto/Relatorios";
import ProcessoRelatorio from "../pages/Processos/Relatorios";
import JuridicoRelatorio from "../pages/Juridico/Relatorios";
import FinanceiroRelatorio from "../pages/Financeiro/Relatorios";
import OrcamentarioRelatorio from "../pages/Orcamentario/Relatorios";
import RhFerias from "../pages/RecursosHumanos/Empregados/Ferias";
import RhFeriasCreate from "../pages/RecursosHumanos/Empregados/Ferias/Create";
import RhFeriasView from "../pages/RecursosHumanos/Empregados/Ferias/View";
import RhFeriasEdit from "../pages/RecursosHumanos/Empregados/Ferias/Edit";
import RhFeriasConfirmar from "../pages/RecursosHumanos/Empregados/Ferias/ConfirmarFerias";
import OrcamentarioReceita from "../pages/Orcamentario/Receita";
import OrcamentarioReceitaCreate from "../pages/Orcamentario/Receita/Create";
import OrcamentarioReceitaEdit from "../pages/Orcamentario/Receita/Edit";
import OrcamentarioReceitaView from "../pages/Orcamentario/Receita/View";
import PoaDePara from "../pages/Orcamentario/POA/DePara";
import ProtocoloFichaPDF from "../pages/Protocolo/FichaPDF";
import InstrumentoFichaPDF from "../pages/Instrumentos/FichaPDF";
import FinanceiroAutorizacaoDespesaFichaPDF from "../pages/Financeiro/AutorizacaoDespesa/FichaPDF";
import FinanceiroAtesteFichaPDF from "../pages/Financeiro/Ateste/FichaPDF";
import OrcamentarioDotacaoFichaPDF from "../pages/Orcamentario/Dotacao/FichaPDF";
import JuridicoFichaPDF from "../pages/Juridico/FichaPDF";
import FornecedorFichaPDF from "../pages/Fornecedores/FichaPDF";
import RHEmpregadosFichaPDF from "../pages/RecursosHumanos/Empregados/FichaPDF";
import ProjetoFichaPDF from "../pages/Projeto/FichaPDF";
import FinanceiroAtesteNotaFiscal from "../pages/Financeiro/Ateste/NotaFiscal";

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Login} />
    <Route path="/dashboard" component={Dashboard} isPrivate />
    {/* Rotas de usuarios */}
    <Route
      path="/usuarios"
      component={Users}
      exact
      isPrivate
      initialComponents
    />
    <Route
      path="/usuarios/cadastrar"
      component={CreateUsers}
      isPrivate
      initialComponents
    />
    <Route
      path="/usuarios/visualizar/:loginId"
      component={ViewUsers}
      isPrivate
      initialComponents
    />
    <Route
      path="/usuarios/editar/:loginId"
      component={EditUsers}
      isPrivate
      initialComponents
    />
    {/* Rotas de Membros */}
    <Route path="/membros/instituicoes" exact component={Members} isPrivate />
    <Route path="/membros/dashboard" component={MembersDashboard} isPrivate />
    <Route
      path="/membros/instituicoes/cadastrar"
      component={RegMembers}
      isPrivate
      initialComponents
    />
    <Route
      path="/membros/instituicoes/documentos/cadastrar/:idInstituicao"
      component={createInstitutionDocument}
      isPrivate
      initialComponents
    />
    <Route
      path="/membros/instituicoes/visualizar/:memberId"
      component={ViewMembers}
      isPrivate
      initialComponents
    />
    <Route
      path="/membros/instituicoes/editar/:memberId"
      component={EditMembers}
      isPrivate
      initialComponents
    />
    <Route
      path="/relatorios/instituicoes"
      component={RelatorioInstituicao}
      isPrivate
      initialComponents
    />
    <Route path="/membros" exact component={Members} isPrivate />
    <Route path="/membros/dashboard" component={MembersDashboard} isPrivate />
    <Route path="/cadastrarmembros" component={RegMembers} isPrivate />
    <Route
      path="/membros/instituicao/documentos/cadastrar/:idInstituicao"
      component={createInstitutionDocument}
      isPrivate
    />
    <Route
      path="/visualizarmembros/:memberId"
      component={ViewMembers}
      isPrivate
    />
    <Route path="/editarmembros/:memberId" component={EditMembers} isPrivate />
    <Route
      path="/relatorios/instituicoes"
      component={RelatorioInstituicao}
      isPrivate
    />
    {/* Rotas de Representantes */}
    <Route
      path="/membros/representantes"
      exact
      component={Representantes}
      isPrivate
    />
    <Route
      path="/membros/representantes/cadastrar"
      component={RegRepresentante}
      isPrivate
      initialComponents
    />
    <Route
      path="/membros/representantes/visualizar/:representativeId"
      component={ViewRepresentante}
      isPrivate
      initialComponents
    />
    <Route
      path="/membros/representantes/editar/:representativeId"
      component={EditRepresentante}
      isPrivate
      initialComponents
    />
    <Route
      path="/relatorios/representantes"
      component={RelatorioRepresentantes}
      isPrivate
      initialComponents
    />
    {/* Rotas de Fornecedores */}
    <Route path="/fornecedores" component={Fornecedores} exact isPrivate />
    <Route
      path="/fornecedores/cadastrar"
      component={RegFornecedor}
      isPrivate
      initialComponents
    />
    <Route
      path="/fornecedores/visualizar/:supplierId"
      component={ViewFornecedor}
      isPrivate
      initialComponents
    />
    <Route
      path="/fornecedores/editar/:supplierId"
      component={EditFornecedor}
      isPrivate
      initialComponents
    />
    <Route
      path="/fornecedores/cnae"
      component={AtividadeCNAE}
      exact
      isPrivate
    />
    <Route
      path="/fornecedores/cnae/cadastrar"
      component={RegCNAE}
      isPrivate
      initialComponents
    />
    <Route
      path="/fornecedores/cnae/editar/:idCnae"
      component={EditCNAE}
      isPrivate
      initialComponents
    />
    <Route
      path="/fornecedores/certidao/:supplierId"
      component={CertidaoFornecedor}
      isPrivate
      initialComponents
    />
    <Route
      path="/fornecedores/fichaPDF/:supplierId"
      component={FornecedorFichaPDF}
      isPrivate
    />
    <Route
      path="/relatorios/fornecedores"
      component={RelatorioFornecedores}
      isPrivate
      initialComponents
    />
    {/* Rotas de Instrumentos */}
    <Route
      initialComponents
      path="/instrumentos"
      component={Instrumentos}
      exact
      isPrivate />
    <Route
      path="/instrumentos/dashboard"
      component={InstrumentsDashboard}
      isPrivate
    />
    <Route
      initialComponents
      path="/instrumentos/cadastrar"
      component={InstrumentoCreate}
      exact
      isPrivate />
    <Route
      initialComponents
      path="/instrumentos/visualizar/:instrumentId"
      component={InstrumentoView}
      isPrivate
    />
    <Route
      initialComponents
      path="/instrumentos/editar/:instrumentId"
      component={InstrumentoEdit}
      isPrivate
    />
    <Route
      path="/instrumentos/fichaPDF/:instrumentId"
      component={InstrumentoFichaPDF}
      isPrivate
    />
    <Route
      path="/relatorios/instrumentos"
      component={RelatorioInstrumentos}
      isPrivate
    />
    {/*Aditivo*/}
    <Route
      initialComponents
      path="/instrumentos/aditivo/cadastrar/:instrumentId"
      component={InstrumentoAditivoCreate}
      isPrivate
    />
    <Route
      initialComponents
      path="/instrumentos/aditivo/visualizar/:idAditivo"
      component={InstrumentoAditivoView}
      isPrivate
    />
    <Route
      initialComponents
      path="/instrumentos/aditivo/editar/:idAditivo"
      component={InstrumentoAditivoEdit}
      isPrivate
    />
    {/*Apostilamento*/}
    <Route
      initialComponents
      path="/instrumentos/apostilamento/cadastrar/:instrumentId"
      component={InstrumentoApostilamentoCreate}
      isPrivate
    />
    <Route
      initialComponents
      path="/instrumentos/apostilamento/visualizar/:idApostilamento"
      component={InstrumentoApostilamentoView}
      isPrivate
    />
    <Route
      initialComponents
      path="/instrumentos/apostilamento/editar/:idApostilamento"
      component={InstrumentoApostilamentoEdit}
      isPrivate
    />
    {/*Rotas de Orçamentário:*/}
    <Route
      path="/orcamentario/orcamento"
      exact
      component={Orcamento}
      isPrivate
      initialComponents
    />
    <Route
      path="/orcamentario/orcamento/cadastrar"
      exact
      component={CadastrarOrcamento}
      isPrivate
      initialComponents
    />
    {/*PAP*/}
    <Route
      path="/orcamentario/pap"
      exact
      component={Pap}
      isPrivate
      initialComponents
    />
    <Route
      path="/orcamentario/pap/visualizar/:idPap"
      component={PapView}
      isPrivate
      initialComponents
    />
    <Route
      path="/orcamentario/pap/cadastrar-identificacao"
      component={PapCreateIdentificacao}
      isPrivate
      initialComponents
    />
    <Route
      path="/orcamentario/pap/editar-identificacao/:idPap"
      component={PapEditIdentificacao}
      isPrivate
      initialComponents
    />
    <Route
      path="/orcamentario/pap/finalidade/:idPap"
      component={PapCreateFinalidade}
      isPrivate
      initialComponents
    />
    <Route
      path="/orcamentario/pap/programa/:idPap"
      component={PapCreatePrograma}
      isPrivate
      initialComponents
    />
    <Route
      path="/orcamentario/pap/acao/:idPap"
      component={PapCreateAcao}
      isPrivate
      initialComponents
    />
    <Route
      path="/orcamentario/pap/orcamento/:idPap"
      component={PapOrcamento}
      isPrivate
      initialComponents
    />
    {/*POA*/}
    <Route
      path="/orcamentario/poa"
      exact
      component={Poa}
      isPrivate
      initialComponents
    />
    <Route
      path="/orcamentario/poa/visualizar/:idPoa"
      component={PoaView}
      isPrivate
      initialComponents
    />
    <Route
      path="/orcamentario/poa/cadastrar-identificacao"
      component={PoaCreateIdentificacao}
      isPrivate
      initialComponents
    />
    <Route
      path="/orcamentario/poa/editar-identificacao/:idPoa"
      component={PoaEditIdentificacao}
      isPrivate
      initialComponents
    />
    <Route
      path="/orcamentario/poa/subacao/:idPoa"
      component={PoaEditSubacao}
      isPrivate
      initialComponents
    />
    <Route
      path="/orcamentario/poa/orcamento/:idPoa"
      component={PoaOrcamento}
      isPrivate
      initialComponents
    />
    <Route
      path="/orcamentario/poa/depara/:idPoa"
      component={PoaDePara}
      isPrivate
      initialComponents
    />
    {/*Dotação*/}
    <Route
      initialComponents
      path="/orcamentario/dotacao"
      component={OrcamentarioDotacao}
      exact
      isPrivate
    />
    <Route
      initialComponents
      path="/orcamentario/dotacao/cadastrar"
      component={OrcamentarioDotacaoCreate}
      exact
      isPrivate
    />
    <Route
      initialComponents
      path="/orcamentario/dotacao/visualizar/:dotacaoId"
      component={OrcamentarioDotacaoView}
      exact
      isPrivate
    />
    <Route
      initialComponents
      path="/orcamentario/dotacao/editar/:dotacaoId"
      component={OrcamentarioDotacaoEdit}
      exact
      isPrivate
    />
    <Route
      initialComponents
      path="/orcamentario/dotacao/validar/:dotacaoId"
      component={OrcamentarioDotacaoValidar}
      exact
      isPrivate
    />
    <Route
      path="/orcamentario/dotacao/fichaPDF/:dotacaoId"
      component={OrcamentarioDotacaoFichaPDF}
      exact
      isPrivate
    />
    <Route
      initialComponents
      path="/orcamentario/receita"
      component={OrcamentarioReceita}
      exact
      isPrivate
    />
    <Route
      initialComponents
      path="/orcamentario/receita/cadastrar"
      component={OrcamentarioReceitaCreate}
      exact
      isPrivate
    />
    <Route
      initialComponents
      path="/orcamentario/receita/visualizar/:receitaId"
      component={OrcamentarioReceitaView}
      exact
      isPrivate
    />
    <Route
      initialComponents
      path="/orcamentario/receita/editar/:receitaId"
      component={OrcamentarioReceitaEdit}
      exact
      isPrivate
    />
    <Route
      initialComponents
      path="/orcamentario/relatorios"
      component={OrcamentarioRelatorio}
      exact
      isPrivate
    />
    {/*Despesas*/}
    <Route path="/orcamentario/cadastros/despesas" component={Despesas} initialComponents exact isPrivate />
    <Route
      path="/orcamentario/cadastros/despesas/cadastrar"
      component={RegDespesa}
      initialComponents
      isPrivate
    />
    <Route
      path="/orcamentario/cadastros/despesas/editar/:idDespesa"
      component={EditDespesa}
      initialComponents
      isPrivate
    />
    {/*Orgão Gestor*/}
    <Route
      path="/orcamentario/cadastros/orgaosgestores"
      component={OrgaosGestores}
      initialComponents
      exact
      isPrivate
    />
    <Route
      path="/orcamentario/cadastros/orgaosgestores/cadastrar"
      component={RegOrgaoGestor}
      initialComponents
      isPrivate
    />
    <Route
      path="/orcamentario/cadastros/orgaosgestores/editar/:idOrgao"
      component={EditOrgaoGestor}
      initialComponents
      isPrivate
    />
    {/*Contrato de Gestão*/}
    <Route
      path="/orcamentario/cadastros/contratosgestao"
      component={ContratosGestao}
      initialComponents
      exact
      isPrivate
    />
    <Route
      path="/orcamentario/cadastros/contratosgestao/cadastrar"
      component={RegContratoGestao}
      initialComponents
      isPrivate
    />
    <Route
      path="/orcamentario/cadastros/contratosgestao/editar/:idContrato"
      component={EditContratoGestao}
      initialComponents
      isPrivate
    />
    {/*Comitê*/}
    <Route path="/orcamentario/cadastros/comites" component={Comites} exact initialComponents isPrivate />
    <Route
      path="/orcamentario/cadastros/comites/cadastrar"
      component={RegComite}
      initialComponents
      isPrivate
    />
    <Route
      path="/orcamentario/cadastros/comites/editar/:idComite"
      component={EditComite}
      initialComponents
      isPrivate
    />
    {/*Finalidade*/}
    {/* <Route path="/finalidades" component={Finalidades} isPrivate />
    <Route path="/cadastrarfinalidade" component={RegFinalidade} isPrivate />
    <Route path="/editarfinalidade" component={EditFinalidade} isPrivate /> */}
    {/*Programa*/}
    {/* <Route path="/programas" component={Programas} isPrivate />
    <Route path="/cadastrarprograma" component={RegPrograma} isPrivate />
    <Route path="/editarprograma" component={EditPrograma} isPrivate /> */}
    {/*Ação*/}
    {/* <Route path="/acoes" component={Acoes} isPrivate />
    <Route path="/cadastraracao" component={RegAcao} isPrivate />
    <Route path="/editaracao" component={EditAcao} isPrivate /> */}
    {/*Subação*/}
    {/* <Route path="/subacoes" component={SubAcoes} isPrivate />
    <Route path="/cadastrarsubacao" component={RegSubAcao} isPrivate />
    <Route path="/editarsubacao" component={EditSubAcao} isPrivate /> */}
    {/*Projeto*/}
    <Route
      path="/projetos"
      exact
      component={Projetos}
      initialComponents
      isPrivate
    />
    <Route
      path="/projetos/cadastrar"
      component={RegProjeto}
      initialComponents
      isPrivate
    />
    <Route
      path="/projetos/visualizar/:idProjeto"
      component={ViewProjeto}
      initialComponents
      isPrivate
    />
    <Route
      path="/projetos/editar/:idProjeto"
      component={EditProjeto}
      initialComponents
      isPrivate
    />
    <Route
      path="/projetos/linha/:idProjeto"
      component={LinhaProjeto}
      initialComponents
      isPrivate
    />
    <Route
      path="/projetos/documento/:idProjeto"
      component={CreateProjectDocument}
      initialComponents
      isPrivate
    />
    <Route
      path="/projetos/fichaPDF/:idProjeto"
      component={ProjetoFichaPDF}
      isPrivate
    />
    <Route
      path="/projetos/relatorios"
      component={ProjetoRelatorio}
      initialComponents
      isPrivate
    />
    {/*Descrição*/}
    <Route
      path="/orcamentario/cadastros/descricoes"
      component={Descricoes}
      initialComponents
      exact
      isPrivate
    />
    <Route
      path="/orcamentario/cadastros/descricoes/cadastrar"
      component={RegDescricao}
      initialComponents
      isPrivate
    />
    <Route
      path="/orcamentario/cadastros/descricoes/editar/:idDescricao"
      component={EditDescricao}
      initialComponents
      isPrivate
    />
    {/*Rotas de RecursosHumanos:*/}
    {/*Empregados*/}
    <Route path="/rh/dashboard" component={RhDashboard} isPrivate />
    <Route path="/rh/empregados" component={Empregados} exact isPrivate />
    <Route
      path="/rh/empregados/cadastrar"
      component={RegEmpregados}
      exact
      isPrivate
      initialComponents
    />
    <Route
      path="/rh/empregados/cadastrarempregado"
      component={RegEmpregado}
      isPrivate
      initialComponents
    />
    <Route
      path="/rh/empregados/cadastrarestagiario"
      component={RegEstagiario}
      isPrivate
      initialComponents
    />
    <Route
      path="/rh/empregados/cadastrarjovem_aprendiz"
      component={RegJovemAprendiz}
      isPrivate
      initialComponents
    />
    <Route
      path="/rh/empregados/cadastrarterceirizado"
      component={RegTerceirizado}
      isPrivate
      initialComponents
    />
    <Route
      path="/rh/empregados/documentos/:empregadoId"
      component={DocumentosEmpregados}
      isPrivate
      initialComponents
    />
    <Route
      path="/rh/empregados/visualizar/:empregadoId"
      component={ViewEmpregado}
      isPrivate
      initialComponents
    />
    <Route
      path="/rh/empregados/editar/:empregadoId"
      component={EditEmpregado}
      isPrivate
      initialComponents
    />
    <Route
      path="/rh/empregados/fichaPDF/:empregadoId"
      component={RHEmpregadosFichaPDF}
      isPrivate
    />
    {/*Férias*/}
    <Route
      initialComponents
      path="/rh/empregados/ferias/:empregadoId"
      component={RhFerias}
      exact
      isPrivate
    />
    <Route
      initialComponents
      path="/rh/empregados/ferias/cadastrar/:empregadoId"
      component={RhFeriasCreate}
      exact
      isPrivate
    />
    <Route
      initialComponents
      path="/rh/empregados/ferias/visualizar/:feriasId"
      component={RhFeriasView}
      exact
      isPrivate
    />
    <Route
      initialComponents
      path="/rh/empregados/ferias/editar/:feriasId"
      component={RhFeriasEdit}
      exact
      isPrivate
    />
    <Route
      initialComponents
      path="/rh/empregados/ferias/confirmar/:feriasId"
      component={RhFeriasConfirmar}
      exact
      isPrivate
    />
    <Route
      path="/relatorios/empregados"
      component={RelatorioEmpregados}
      isPrivate
      initialComponents
    />
    {/*Cadastros de RH*/}
    {/*Gerencia*/}
    <Route
      path="/rh/cadastros/gerencia"
      exact
      component={GerenciaRh}
      isPrivate
    />
    <Route
      path="/rh/cadastros/gerencia/cadastrar"
      component={GerenciaRhCreate}
      isPrivate
      initialComponents
    />
    <Route
      path="/rh/cadastros/gerencia/editar/:gerenteId"
      component={GerenciaRhEdit}
      isPrivate
      initialComponents
    />
    <Route
      path="/rh/cadastros/gerencia/visualizar/:gerenteId"
      component={GerenciaRhView}
      isPrivate
      initialComponents
    />
    {/*Cargo*/}
    <Route path="/rh/cadastros/cargo" exact component={CargoRh} isPrivate />
    <Route
      path="/rh/cadastros/cargo/cadastrar"
      component={CargoRhCreate}
      isPrivate
      initialComponents
    />
    <Route
      path="/rh/cadastros/cargo/editar/:cargoId"
      component={CargoRhEdit}
      isPrivate
      initialComponents
    />
    <Route
      path="/rh/cadastros/cargo/visualizar/:cargoId"
      component={CargoRhView}
      isPrivate
      initialComponents
    />
    {/*Organização*/}
    <Route
      path="/rh/cadastros/organizacao"
      exact
      component={OrganizacaoRh}
      isPrivate
    />
    <Route
      path="/rh/cadastros/organizacao/cadastrar"
      component={OrganizacaoRhCreate}
      isPrivate
      initialComponents
    />
    <Route
      path="/rh/cadastros/organizacao/editar/:organizacaoId"
      component={OrganizacaoRhEdit}
      isPrivate
      initialComponents
    />
    <Route
      path="/rh/cadastros/organizacao/visualizar/:organizacaoId"
      component={OrganizacaoRhView}
      isPrivate
      initialComponents
    />
    {/*Unidades*/}
    <Route path="/rh/cadastros/unidade" exact component={UnidadeRh} isPrivate />
    <Route
      path="/rh/cadastros/unidade/cadastrar"
      component={UnidadeRhCreate}
      isPrivate
      initialComponents
    />
    <Route
      path="/rh/cadastros/unidade/editar/:unidadeId"
      component={UnidadeRhEdit}
      isPrivate
      initialComponents
    />
    <Route
      path="/rh/cadastros/unidade/visualizar/:unidadeId"
      component={UnidadeRhView}
      isPrivate
      initialComponents
    />
    {/*Bens e Patrimonios*/}
    <Route path="/bens-e-patrimonios" component={BensPatrimonios} isPrivate />
    {/*Almoxarifado e estoque*/}
    <Route
      path="/almoxarifado-e-estoque"
      component={AlmoxarifadoEstoque}
      isPrivate
    />
    {/*Assinatura*/}
    <Route path="/assinatura" component={Assinatura} isPrivate />
    {/*Processos*/}
    <Route initialComponents path="/processos" component={Processos} exact isPrivate />
    <Route
      initialComponents
      path="/processos/cadastrar"
      component={ProcessoCreate}
      isPrivate
    />
    <Route
      path="/processos/visualizar/:processoId"
      component={ProcessoView}
      isPrivate
      initialComponents
    />
    <Route
      path="/processos/editar/:processoId"
      component={ProcessoEdit}
      isPrivate
      initialComponents
    />
    <Route
      initialComponents
      path="/processos/normainterna/:processoId"
      component={ProcessoNormaInterna}
      isPrivate
    />
    <Route
      initialComponents
      path="/processos/planejamento/:processoId"
      component={ProcessoPlanejamento}
      isPrivate
    />
    <Route
      initialComponents
      path="/processos/acaoplanejamento/:processoId"
      component={ProcessoAcaoPlanejamento}
      exact
      isPrivate
    />
    <Route
      initialComponents
      path="/processos/acaoplanejamento/atualizar/:planejamentoId"
      component={ProcessoAtualizarAcaoPlanejamento}
      isPrivate
    />
    <Route
      initialComponents
      path="/processos/vincularprotocolo/:processoId"
      component={ProcessoVincularProtocolo}
      isPrivate
    />
    <Route
      path="/processos/fichaPDF/:processoId"
      component={ProcessoFichaPDF}
      isPrivate
    />
    <Route
      initialComponents
      path="/processos/relatorios"
      component={ProcessoRelatorio}
      isPrivate
    />
    {/*Cadastros de Processo*/}
    {/*Tipo do Processo*/}
    <Route
      initialComponents
      path="/processos/cadastros/tipoprocesso"
      component={TipoProcesso}
      exact
      isPrivate
    />
    <Route
      initialComponents
      path="/processos/cadastros/tipoprocesso/cadastrar"
      component={TipoProcessoCreate}
      isPrivate
    />
    <Route
      initialComponents
      path="/processos/cadastros/tipoprocesso/visualizar/:tipoProcessoId"
      component={TipoProcessoView}
      isPrivate
    />
    <Route
      initialComponents
      path="/processos/cadastros/tipoprocesso/editar/:tipoProcessoId"
      component={TipoProcessoEdit}
      isPrivate
    />
    {/*Protocolo*/}
    <Route
      initialComponents
      path="/protocolo"
      component={Protocolo}
      exact
      isPrivate
    />
    <Route
      initialComponents
      path="/protocolo/cadastrar"
      component={ProtocoloCreate}
      isPrivate
    />
    <Route
      initialComponents
      path="/protocolo/visualizar/:protocoloId"
      component={ProtocoloView}
      isPrivate
    />
    <Route
      initialComponents
      path="/protocolo/editar/:protocoloId"
      component={ProtocoloEdit}
      isPrivate
    />
    <Route
      initialComponents
      path="/protocolo/comprovante/:protocoloId"
      component={ProtocoloComprovante}
      isPrivate
    />
    <Route
      path="/protocolo/fichaPDF/:protocoloId"
      component={ProtocoloFichaPDF}
      isPrivate
    />
    <Route
      initialComponents
      path="/protocolo/relatorios"
      component={RelatorioProtocolo}
      exact
      isPrivate
    />
    {/*Cadastros de Protocolo*/}
    {/*Solicitante Externo*/}
    <Route
      initialComponents
      path="/protocolo/cadastros/solicitante_externo"
      component={SolicitanteExterno}
      exact
      isPrivate
    />
    <Route
      initialComponents
      path="/protocolo/cadastros/solicitante_externo/cadastrar"
      component={SolicitanteExternoCreate}
      exact
      isPrivate
    />
    <Route
      initialComponents
      path="/protocolo/cadastros/solicitante_externo/visualizar/:solicitanteId"
      component={SolicitanteExternoView}
      exact
      isPrivate
    />
    <Route
      initialComponents
      path="/protocolo/cadastros/solicitante_externo/editar/:solicitanteId"
      component={SolicitanteExternoEdit}
      exact
      isPrivate
    />
    {/*Origem da Demanda*/}
    <Route
      initialComponents
      path="/protocolo/cadastros/origem_demanda"
      component={OrigemDemanda}
      exact
      isPrivate
    />
    <Route
      initialComponents
      path="/protocolo/cadastros/origem_demanda/cadastrar"
      component={OrigemDemandaCreate}
      exact
      isPrivate
    />
    <Route
      initialComponents
      path="/protocolo/cadastros/origem_demanda/visualizar/:origemId"
      component={OrigemDemandaView}
      exact
      isPrivate
    />
    <Route
      initialComponents
      path="/protocolo/cadastros/origem_demanda/editar/:origemId"
      component={OrigemDemandaEdit}
      exact
      isPrivate
    />
    {/*Instância do protocolo*/}
    <Route
      initialComponents
      path="/protocolo/cadastros/instancia"
      component={InstanciaProtocolo}
      exact
      isPrivate
    />
    <Route
      initialComponents
      path="/protocolo/cadastros/instancia/cadastrar"
      component={InstanciaProtocoloCreate}
      exact
      isPrivate
    />
    <Route
      initialComponents
      path="/protocolo/cadastros/instancia/visualizar/:instanciaId"
      component={InstanciaProtocoloView}
      exact
      isPrivate
    />
    <Route
      initialComponents
      path="/protocolo/cadastros/instancia/editar/:instanciaId"
      component={InstanciaProtocoloEdit}
      exact
      isPrivate
    />
    {/*Jurídico*/}
    <Route
      initialComponents
      path="/juridico"
      component={Juridico}
      exact
      isPrivate
    />
    <Route
      initialComponents
      path="/juridico/cadastrar"
      component={JuridicoCreate}
      exact
      isPrivate
    />
    <Route
      initialComponents
      path="/juridico/visualizar/:processoJuridicoId"
      component={JuridicoView}
      exact
      isPrivate
    />
    <Route
      initialComponents
      path="/juridico/editar/:processoJuridicoId"
      component={JuridicoEdit}
      exact
      isPrivate
    />
    <Route
      initialComponents
      path="/juridico/historicoprocesso/:processoJuridicoId"
      component={JuridicoHistoricoProcesso}
      exact
      isPrivate
    />
    <Route
      initialComponents
      path="/juridico/finalizarprocesso/:processoJuridicoId"
      component={JuridicoFinalizarProcesso}
      exact
      isPrivate
    />
    <Route
      path="/juridico/fichaPDF/:processoJuridicoId"
      component={JuridicoFichaPDF}
      exact
      isPrivate
    />
    <Route
      initialComponents
      path="/juridico/relatorios"
      component={JuridicoRelatorio}
      exact
      isPrivate
    />
    {/*Financeiro*/}
    {/*Ateste*/}
    <Route
      initialComponents
      path="/financeiro/formularioateste"
      component={FinanceiroAteste}
      exact
      isPrivate />
    <Route
      initialComponents
      path="/financeiro/formularioateste/cadastrar"
      component={FinanceiroAtesteCreate}
      isPrivate />
    <Route
      initialComponents
      path="/financeiro/formularioateste/visualizar/:atesteId"
      component={FinanceiroAtesteView}
      isPrivate
    />
    <Route
      initialComponents
      path="/financeiro/formularioateste/editar/:atesteId"
      component={FinanceiroAtesteEdit}
      isPrivate
    />
    <Route
      initialComponents
      path="/financeiro/formularioateste/notafiscal/:atesteId"
      component={FinanceiroAtesteNotaFiscal}
      isPrivate
    />
    <Route
      path="/financeiro/formularioateste/fichaPDF/:atesteId"
      component={FinanceiroAtesteFichaPDF}
      isPrivate
    />
    {/*Autorização de Despesa*/}
    <Route
      initialComponents
      path="/financeiro/autorizacaodespesa"
      component={FinanceiroAutorizacaoDespesa}
      exact
      isPrivate />
    <Route
      initialComponents
      path="/financeiro/autorizacaodespesa/cadastrar"
      component={FinanceiroAutorizacaoDespesaCreate}
      isPrivate />
    <Route
      initialComponents
      path="/financeiro/autorizacaodespesa/visualizar/:autorizacaoDespesaId"
      component={FinanceiroAutorizacaoDespesaView}
      isPrivate />
    <Route
      initialComponents
      path="/financeiro/autorizacaodespesa/realizarpagamento/:autorizacaoDespesaId"
      component={FinanceiroRealizarPagamentoDespesa}
      isPrivate
    />
    <Route
      initialComponents
      path="/financeiro/autorizacaodespesa/informarpagamento/:autorizacaoDespesaId"
      component={FinanceiroInformarPagamentoDespesa}
      isPrivate
    />
    <Route
      path="/financeiro/autorizacaodespesa/fichaPDF/:autorizacaoDespesaId"
      component={FinanceiroAutorizacaoDespesaFichaPDF}
      isPrivate />
    {/*Cadastros*/}
    {/*Conta Bancária*/}
    <Route
      initialComponents
      path="/financeiro/cadastros/contabancaria"
      component={FinanceiroContaBancaria}
      exact
      isPrivate
    />
    <Route
      initialComponents
      path="/financeiro/cadastros/contabancaria/cadastrar"
      component={FinanceiroContaBancariaCreate}
      exact
      isPrivate
    />
    <Route
      initialComponents
      path="/financeiro/cadastros/contabancaria/visualizar/:contaBancariaId"
      component={FinanceiroContaBancariaView}
      exact
      isPrivate
    />
    <Route
      initialComponents
      path="/financeiro/cadastros/contabancaria/editar/:contaBancariaId"
      component={FinanceiroContaBancariaEdit}
      exact
      isPrivate
    />
    <Route
      initialComponents
      path="/financeiro/relatorios"
      component={FinanceiroRelatorio}
      exact
      isPrivate
    />
  </Switch>
);

export default Routes;
