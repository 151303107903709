import { useEffect, useState } from "react";
import useProjeto from "../../actions/cruds/useProjeto";
import MainTable from "../../components/MainTable";
import { Title } from "../../styles/global";
import { Container } from "./styles";

interface SubProps {
  nome: string;
  sub_action: {
    nome: string;
    codigo: string;
    tbl_action: {
      nome: string;
      program: {
        nome: string;
        finality: {
          nome: string;

          expense_type: {
            nome: string;
          }
          comite: {
            nome: string;

            management_contract: {
              nome: string;

              organization: {
                nome: {
                  nome: string;
                }
              }
            }
          }
        }
      }
    };
  };
};

interface DataProps {
  id: string;
  nome: string;
  codigo: string;
  subacao: SubProps[];
  project_lines: SubProps[];
  expense_type_id: string;
  ativo: string;
}

export function Projetos() {
  const { projetos, getProjetos } = useProjeto();

  const [searchValue, setSearchValue] = useState<string>("");
  const [perPage, setPerPage] = useState<number>()

  useEffect(() => {
    const delayedSearch = setTimeout(() => {
      getProjetos([{ label: "busca_rapida", value: searchValue }, { label: "per", value: perPage }]);
    }, 500);

    return () => clearTimeout(delayedSearch);
  }, [searchValue]);

  const handleItemsPerPage = (per_page: number) => {
    setPerPage(per_page);
    getProjetos([{ label: "busca_rapida", value: searchValue }, { label: "per", value: per_page }]);
  };

  const handlePagination = (goBack = false) => {
    const page = goBack ? -1 : 1;

    getProjetos([{ label: "busca_rapida", value: searchValue }, { label: "per", value: perPage }, { label: "page", value: projetos.pagination.current_page + page }]);
  };

  const organizeData = (data: DataProps[]) => {
    if (typeof data === 'object') {
      data = data.map(d => ({
        id: d.id,
        descricao: d.nome,
        rateio: d.rateio ? "Sim" : "Não",
        ativo: d.ativo
      }));
    }
    return data;
  }

  const columnData = [
    { headerName: "ID", field: "id" },
    { headerName: "Descrição", field: "descricao" },
    { headerName: "Rateio", field: "rateio" },
    { headerName: "Status", field: "ativo" }
  ];

  return (
    <>
      <Title color='#333'>
        <span>
          Gestão de projetos
        </span>
      </Title>
      <Container color='#FFF'>
        <MainTable
          columns={columnData}
          data={organizeData(projetos.data)}
          isMaxWidthCell
          pagination={projetos.pagination}
          handleItemsPerPage={handleItemsPerPage}
          handlePagination={handlePagination}
          onSearchChange={setSearchValue}
          registerLink="/projetos/cadastrar"
          dropdown={{
            view: { linkTo: "projetos/visualizar" },
            editar: { linkTo: "projetos/editar" },
            linha: { linkTo: "projetos/linha" },
            documentos: { linkTo: "projetos/documento" },
            desativar: { linkTo: "projects", desactiveText: "Desativar" }
          }}
        />
      </Container>
    </>
  );
};