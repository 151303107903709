import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";

import { DespesaContextProvider } from "./contexts/despesaContext";

import { FornecedorContextProvider } from "./contexts/fornecedorContext";
import { ProcessoFormProvider } from "./contexts/processoContext";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    {/* <FornecedorContextProvider>
      <DespesaContextProvider> */}
        <ProcessoFormProvider>
          <App />
        </ProcessoFormProvider>
      {/* </DespesaContextProvider>
    </FornecedorContextProvider> */}
  </React.StrictMode>
);
