import React, { useEffect, useState } from "react";
import { Container, Title } from "./styles";
import MainTable from "../../../../components/MainTable";
import { CSidebar } from "../../../../components/Sidebar";
import Header from "../../../../components/Header";
import api from "../../../../services/api";

const CargoRh: React.FC = () => {
  const [cargos, setCargos] = useState([]);

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const delayedSearch = setTimeout(() => {
      api
        .get(`positions?busca_rapida=${searchValue}`)
        .then((response) => {
          setCargos(response.data);
        })
        .catch((error) => console.log(error));
    }, 500);

    return () => clearTimeout(delayedSearch);
  }, [searchValue]);

  const handlePagination = (goBack = false) => {
    const page = goBack ? -1 : 1;

    api
      .get(`positions?page=${cargos.pagination.current_page + page}`)
      .then((response) => {
        setCargos(response.data);
      })
      .catch((error) => console.log(error));
  };

  const columnData = [
    { headerName: "Código", field: "id" },
    { headerName: "Cargo", field: "nome" },
    { headerName: "Vinculo", field: "tipo_vinculo" },
    { headerName: "Situação", field: "ativo" },
  ];

  const organizeData = (data) => {
    if (typeof data === "object") {
      data = data.map((d) => ({
        id: d.id,
        nome: d.nome,
        tipo_vinculo: d.employee_type.nome,
        ativo: d.ativo,
      }));
    }

    return data;
  };

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <div style={{ height: "100%" }}>
        <CSidebar />
      </div>
      <div style={{ display: "block", width: "100%" }}>
        <Header />
        <Title color="#333">
          <span>Gestão de cargos</span>
        </Title>
        <Container color="#FFF">
          <MainTable
            onSearchChange={setSearchValue}
            data={organizeData(cargos.data)}
            pagination={cargos.pagination}
            handlePagination={handlePagination}
            hideFilter={true}
            isMaxWidthCell
            registerLink="/rh/cadastros/cargo/cadastrar"
            columns={columnData}
            dropdown={{
              view: { linkTo: "/rh/cadastros/cargo/visualizar" },
              editar: { linkTo: "/rh/cadastros/cargo/editar" },
              desativar: { linkTo: "positions" },
            }}
          />
        </Container>
      </div>
    </div>
  );
};

export default CargoRh;
