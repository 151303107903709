import { useEffect, useState } from "react";
import { Title } from "../../../styles/global";
import MainTable from "../../../components/MainTable";
import { Container } from "./styles";
import useAteste from "../../../actions/cruds/Financeiro/useAteste";
import { formatarData, formatarReais } from "../../../utils/formatDados";


const FinanceiroAteste: React.FC = () => {
  const { atestes, getAtestes } = useAteste();

  const [searchValue, setSearchValue] = useState<string>("");
  const [perPage, setPerPage] = useState<number>()

  const optionsSituacao = [
    { label: "Aguardando conclusão", value: "aguardando_conclusao" },
    { label: "Concluído", value: "concluido" },
    { label: "Cancelado", value: "cancelado" },
    { label: "Cadastro Concluído", value: "cadastro_concluido" },
  ];

  useEffect(() => {
    const delayedSearch = setTimeout(() => {
      getAtestes([{ label: "busca_rapida", value: searchValue }, { label: "per", value: perPage }]);
    }, 500);

    return () => clearTimeout(delayedSearch);
  }, [searchValue]);

  const handleItemsPerPage = (per_page: number) => {
    setPerPage(per_page);
    getAtestes([{ label: "busca_rapida", value: searchValue }, { label: "per", value: per_page }]);
  };

  const handlePagination = (goBack = false) => {
    const page = goBack ? -1 : 1;

    getAtestes([{ label: "busca_rapida", value: searchValue }, { label: "per", value: perPage }, { label: "page", value: atestes.pagination.current_page + page }]);
  };

  const organizeData = (data: any[]) => {
    const withoutData = <i>Sem dados</i>;

    if (typeof data === "object") {
      data = data.map((d) => ({
        id: d.id,
        numero: d.numero_ateste ?? withoutData,
        data_solicitacao: formatarData(d.created_at, false) ?? withoutData,
        instrumento: d.contractual_instrument?.numero ?? withoutData,
        favorecido: d.supplier?.nome_razao_social ?? withoutData,
        valor: formatarReais(d.valor) ?? withoutData,
        situacao: d.situacao ? optionsSituacao.find(o => o.value == d?.situacao)?.label : withoutData,
        ativo: d.ativo,
      }));
    }
    return data;
  };

  const columnData = [
    // { headerName: "ID", field: "id" },
    { headerName: "Número do Ateste", field: "numero" },
    { headerName: "Data da Solicitação", field: "data_solicitacao" },
    { headerName: "Instrumento", field: "instrumento" },
    { headerName: "Favorecido", field: "favorecido" },
    { headerName: "Valor", field: "valor" },
    { headerName: "Situação", field: "situacao" },
    // { headerName: "Status", field: "ativo" },
  ];

  return (
    <>
      <Title color="#333">
        <span>Gestão de Formulário de ateste</span>
      </Title>
      <Container color="#FFF">
        <MainTable
          columns={columnData}
          data={organizeData(atestes.data)}
          pagination={atestes.pagination}
          isMaxWidthCell
          handleItemsPerPage={handleItemsPerPage}
          handlePagination={handlePagination}
          onSearchChange={setSearchValue}
          registerLink="/financeiro/formularioateste/cadastrar"
          dropdown={{
            view: { linkTo: "/financeiro/formularioateste/visualizar" },
            editar: { linkTo: "/financeiro/formularioateste/editar" },
            notaFiscal: { linkTo: "/financeiro/formularioateste/notafiscal" },
            // desativar: { linkTo: "attests" },
            cancelar: { linkTo: "attests", activateSituacao: "cadastro_concluido" }
          }}
        />
      </Container>
    </>
  );
};

export default FinanceiroAteste;
