import { faBell, faCircleUser, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { Container, ContentContainer, ContentMenu, SelectOrg } from './styles';

const Header: React.FC = () => {

    const {login, signOut} = useAuth();

    return (
        <Container className='superiorMenuContainer'>
            <ContentContainer>
                <ContentMenu>
                    <SelectOrg
                        name="color"
                        options={[{label: "AGEVAP", value: 1}, {label: "AGEDOCE", value: 2}, {label: "Todas as organizações", value: 3}]}
                        placeholder="Selecione uma organização"
                    />

                    <Link to="#">
                        <FontAwesomeIcon icon={faBell} size="2xl" />
                    </Link>
                    
                    <Link to="#">
                        <FontAwesomeIcon icon={faCircleUser} size="2xl" />
                    </Link>
                    
                    <p>{login.nome}</p>

                    <Link to="#" className='logout' onClick={signOut}>
                        <FontAwesomeIcon icon={faRightFromBracket} size="2xl" />
                    </Link>
                    
                </ContentMenu>
            </ContentContainer>
        </Container>
    )
};

export default Header;