import React from 'react';
import Header from '../../../components/Header';
import Panel from '../../../components/Panel';
import { CSidebar } from '../../../components/Sidebar';
import { Container } from '../../../styles/global';
import { Loader } from './styles';

const RhDashboard: React.FC = () => {
    return (
        <div style={{display: 'flex', height: '100%'}}>
            <div style={{height: '100%'}}>
                <CSidebar />
            </div>
            <div style={{display: 'block', width: '100%'}}>
                <Header /> 

                <Container>
                    <Panel titulo='Dashboard de Recursos Humanos'>
                        <Loader src="https://app.powerbi.com/view?r=eyJrIjoiNzlkNzhjMjMtMDI0MC00NjFjLWI1MDItODQyNDUyZmVmZDFlIiwidCI6ImVmNWVhOGVhLWNjMzctNGFiMS1iNzYxLTI1YmQzNmNhMWEyMyJ9" frameborder="0" title='ArcGIS Dashboard'></Loader>
                    </Panel>
                </Container>
            </div>
        </div> 
    );
};

export default RhDashboard;