import { Container } from "./styles";
import MainTable from "../../components/MainTable";
import { useEffect, useState } from "react";
import NumeroProcessoModal from "../../components/NumeroProcessoModal";
import { Title } from "../../styles/global";
import useProcesso from "../../actions/cruds/Processos/useProcesso";

const Processos: React.FC = () => {
  const { processos, getProcessos } = useProcesso();

  const [showModalNumeroProcesso, setShowModalNumeroProcesso] = useState(false);
  const [numeroProcesso, setNumeroProcesso] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [perPage, setPerPage] = useState<number>()

  const optionsSituacao = [
    { label: "Em andamento", value: "em_andamento" },
    { label: "Concluído", value: "concluido" },
  ];

  useEffect(() => {
    const delayedSearch = setTimeout(() => {
      getProcessos([{ label: "busca_rapida", value: searchValue }, { label: "per", value: perPage }]);
    }, 500);

    return () => clearTimeout(delayedSearch);
  }, [searchValue]);

  const handleItemsPerPage = (per_page: number) => {
    setPerPage(per_page);
    getProcessos([{ label: "busca_rapida", value: searchValue }, { label: "per", value: per_page }]);
  };

  const handlePagination = (goBack = false) => {
    const page = goBack ? -1 : 1;

    getProcessos([{ label: "busca_rapida", value: searchValue }, { label: "per", value: perPage }, { label: "page", value: processos.pagination.current_page + page }]);
  };

  const organizeData = (data: any[]) => {
    const withoutData = <i>Sem dados</i>;

    if (typeof data === "object") {
      data = data.map((item) => {
        return {
          id: item.id,
          processo: item.numero ?? withoutData,
          codigo: item.codigo,
          contrato: item.rateio ? "Rateio" : item.management_contracts && item.management_contracts.length > 0 ? item.management_contracts[0].nome_completo : withoutData,
          gestor: item.gestor_processo?.nome ?? withoutData,
          responsavel: item.administration?.nome ?? withoutData,
          situacao: item.status ? optionsSituacao.find(o => o.value == item?.status).label : withoutData,
          ativo: item.ativo,
        };
      });
    }

    return data;
  };

  const columnData = [
    { headerName: "Número do processo - SIGAD", field: "processo" },
    { headerName: "Código SIGA", field: "codigo" },
    { headerName: "Contrato de Gestão", field: "contrato" },
    { headerName: "Gestor", field: "gestor" },
    { headerName: "Área responsável", field: "responsavel" },
    { headerName: "Situação", field: "situacao" },
    { headerName: "Status", field: "ativo" },
  ];

  console.log(processos);

  return (
    <>
      <Title color="#333">
        <span>Gestão de Processo</span>
      </Title>
      <Container color="#FFF">
        <NumeroProcessoModal
          processoId={numeroProcesso}
          isOpen={showModalNumeroProcesso}
          handleClose={() => setShowModalNumeroProcesso(false)}
        />

        <MainTable
          handleItemsPerPage={handleItemsPerPage}
          columns={columnData}
          data={organizeData(processos.data)}
          pagination={processos.pagination}
          handlePagination={handlePagination}
          onSearchChange={setSearchValue}
          isMaxWidthCell
          registerLink="/processos/cadastrar"
          dropdown={{
            view: { linkTo: "/processos/visualizar" },
            editar: { linkTo: "processos/editar" },
            planejamento: { linkTo: "/processos/planejamento" },
            acaoPlanejamento: { linkTo: "/processos/acaoplanejamento" },
            normaInterna: { linkTo: "/processos/normainterna" },
            vincularProtocolo: { linkTo: "processos/vincularprotocolo" },
            desativar: { linkTo: "law_processes" },
            numeroProcesso: {
              openModal: () => setShowModalNumeroProcesso(true),
              updateId: setNumeroProcesso,
            },
          }}
        />
      </Container>
    </>
  );
};

export default Processos;
