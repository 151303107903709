import { useEffect, useState } from "react";
import { Title } from "../../../styles/global";
import MainTable from "../../../components/MainTable";
import { Container } from "./styles";
import { formatarData, formatarReais } from "../../../utils/formatDados";
import useReceita from "../../../actions/cruds/Orcamentario/useReceita";
import { DialogInDev } from "../../../components/ModalEmDev";

const OrcamentarioReceita: React.FC = () => {
  const { receitas, getReceitas } = useReceita();

  const [searchValue, setSearchValue] = useState<string>("");
  const [perPage, setPerPage] = useState<number>()

  const optionsSituacao = [
    { label: "Aguardando realização do pagamento", value: "aguardando_realizacao_pagamento" },
    { label: "Aguardando confirmação do pagamento", value: "aguardando_confirmacao_pagamento" },
    { label: "Concluído", value: "concluido" },
    { label: "Cancelado", value: "cancelado" },
  ];

  // useEffect(() => {
  //   const delayedSearch = setTimeout(() => {
  //     getReceitas([{ label: "busca_rapida", value: searchValue }, { label: "per", value: perPage }]);
  //   }, 500);

  //   return () => clearTimeout(delayedSearch);
  // }, [searchValue]);

  const handleItemsPerPage = (per_page: number) => {
    setPerPage(per_page);
    getReceitas([{ label: "busca_rapida", value: searchValue }, { label: "per", value: per_page }]);
  };

  const handlePagination = (goBack = false) => {
    const page = goBack ? -1 : 1;

    getReceitas([{ label: "busca_rapida", value: searchValue }, { label: "per", value: perPage }, { label: "page", value: receitas.pagination.current_page + page }]);
  };

  const organizeData = (data: any[]) => {
    const withoutData = <i>Sem dados</i>;

    if (typeof data === "object") {
      data = data.map((d) => ({
        id: d.id,
        codigo: d.codigo,
        data: formatarData(d.created_at, false) ?? withoutData,
        instrumento: d.attest?.contractual_instrument?.numero ?? withoutData,
        favorecido: d.attest?.supplier?.nome_razao_social ?? withoutData,
        valor: formatarReais(d.invoice?.valor) ?? withoutData,
        situacao: d.situacao ? optionsSituacao.find(o => o.value == d?.situacao)?.label : withoutData,
        ativo: d.ativo,
      }));
    }
    return data;
  };

  const columnData = [
    { headerName: "ID", field: "id" },
    { headerName: "Código", field: "codigo" },
    { headerName: "Ano", field: "ano" },
    { headerName: "Mês", field: "mes" },
    { headerName: "Organização", field: "organizacao" },
    { headerName: "Contrato de Gestão", field: "contrato_gestao" },
    { headerName: "Comitê", field: "comite" },
    { headerName: "Tipo de Receita", field: "tipo_receita" },
    { headerName: "Valor", field: "valor" },
    { headerName: "Situação", field: "situacao" },
    { headerName: "Status", field: "ativo" },
  ];

  const dataMock = [
    { id: "1", codigo: "0123456789", ano: "2024", mes: "Janeiro", organizacao: "Organização1", contrato_gestao: "ContratoGestão1", comite: "Comitê1", tipo_receita: "TipoReceita", valor: "R$123,45", situacao: "Aguardando Confirmação", ativo: true }
  ]

  return (
    <>
      <Title color="#333">
        <span>Gestão de Receita anual</span>
      </Title>
      <Container color="#FFF">
        <MainTable
          columns={columnData}
          data={dataMock}
          // data={organizeData(receitas.data)}
          pagination={receitas.pagination}
          isMaxWidthCell
          handleItemsPerPage={handleItemsPerPage}
          handlePagination={handlePagination}
          onSearchChange={setSearchValue}
          registerLink="/orcamentario/receita/cadastrar"
          dropdown={{
            view: { linkTo: "/orcamentario/receita/visualizar" },
            editar: { linkTo: "/orcamentario/receita/editar" },
            cancelar: { linkTo: "" },
            // desativar: { linkTo: "" },
          }}
        />
      </Container>
      <DialogInDev open={true} modulo={"Gestão de Receita anual"}></DialogInDev>
    </>
  );
};

export default OrcamentarioReceita;
