import { useEffect } from "react";
import useOrgaoGestor from "../../../../actions/cruds/useOrgaoGestor";
import MainTable from "../../../../components/MainTable";
import { Title } from "../../../../styles/global";
import { Container } from "./styles";


export function OrgaosGestores() {

  const { orgaoGestor, getOrgaoGestor } = useOrgaoGestor();

  useEffect(() => {
    getOrgaoGestor();
  }, [])


  const organizeData = (data: any) => {

    if (typeof data === 'object') {
      data = data.map((d: {
        id: string;
        codigo: string;
        nome: string;
        ativo: string;
      }) => ({
        id: d.id,
        codigo: d.codigo,
        nome: d.nome,
        ativo: d.ativo
      }));
    }
    return data;
  }

  const columnData = [
    { headerName: "Orgão gestor", field: "nome" },
    { headerName: "Status", field: "ativo" }
  ];

  const handlePagination = (goBack = false) => {
    const page = goBack ? -1 : 1;

    getOrgaoGestor([{ label: "page", value: orgaoGestor.pagination.current_page + page }]);
  };

  return (
    <>
      <Title color='#333'>
        <span>
          Gestão dos Órgãos Gestores
        </span>
      </Title>
      <Container color='#FFF'>
        <MainTable
          data={organizeData(orgaoGestor.data)}
          pagination={orgaoGestor.pagination}
          handlePagination={handlePagination}
          hideFilter={true}
          registerLink="/orcamentario/cadastros/orgaosgestores/cadastrar"
          columns={columnData}
          dropdown={{
            editar: { linkTo: "/orcamentario/cadastros/orgaosgestores/editar" },
            desativar: { linkTo: "management_entities", desactiveText: "Desativar" },
          }}
        />
      </Container>
    </>
  )
}